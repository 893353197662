import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import lang from 'src/lang/lang';
import { CONTANTS } from 'src/utils';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof DatePicker.RangePicker>, IBaseFormItemProps {}

export function BaseDateRange(props: IProps) {
  return (
    <BaseFormItem {...props}>
      <DatePicker.RangePicker
        {...props}
        placeholder={[lang.t('achievement.componment.start_date'), lang.t('achievement.componment.end_date')]}
        format={props.format || CONTANTS.DATE}
        defaultValue={props.defaultValue || [dayjs().subtract(1, 'M'), dayjs()]}
        size={props.size || 'large'}
        style={{ width: '100%' }}
      />
    </BaseFormItem>
  );
}
