import { memo } from 'react';
import useMessageStore from 'src/stores/message';
import { IBaseProps } from 'src/types';

interface IProps extends IBaseProps {}

function MessageProvider(props: IProps) {
  useMessageStore();
  return props.children;
}

export default memo(MessageProvider);
