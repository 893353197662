import { App } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SpinnerLoader } from './libraries';
import { ConfigAntdProvider, ErrorProvider, MessageProvider, RouteProvider } from './providers';
import { persistor, store } from './stores';
import './styles/custom.scss';

// Extend Day.js with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.locale(localStorage.getItem('lang') || navigator.language?.split('-')[0] || 'vi');

function AppClient() {
  return (
    <Provider store={store}>
      <PersistGate loading={<SpinnerLoader bg={false} />} persistor={persistor}>
        <ConfigAntdProvider>
          <App>
            <MessageProvider>
              <ErrorProvider>
                <RouteProvider />
              </ErrorProvider>
            </MessageProvider>
          </App>
        </ConfigAntdProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppClient;
