import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from 'src/lang/lang';

const userLang = navigator.language;

const initialState: { locale: string } = {
  locale: i18n.locale || userLang?.split('-')[0] || 'vi',
};

const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setLang: (state, { payload }: PayloadAction<{ locale: string }>) => {
      i18n.locale = payload.locale;
      localStorage.setItem('lang', payload.locale);

      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { setLang } = langSlice.actions;

export default langSlice.reducer;
