import { IBaseResponse, IParams } from 'src/types';
import { IDocument } from 'src/types/document.type';
import { http } from 'src/utils';
import BaseService from './base.service';

export default class DocumentService extends BaseService<IDocument> {
  constructor() {
    super('/items/document');
  }

  public async get(query: IParams) {
    const { data } = await http.get<IBaseResponse<IDocument[]>>(`${this.url}`, { params: query });
    return data.data;
  }

  public async createDocument(body: IDocument) {
    const { data } = await http.post<IBaseResponse<IDocument>>(`${this.url}`, body);
    return data.data;
  }

  public async updateDocument(obj: IDocument, id: string) {
    const { data } = await http.patch<IDocument>(`${this.url}/${id}`, obj);
    return data;
  }

  public async deleteDocument(id: string) {
    const { data } = await http.delete<IDocument>(`${this.url}/${id}`);
    return data;
  }
}
