import { I18n } from 'i18n-js';
import en from './en.json';
import vi from './vi.json';

interface ILang {
  key: string;
  image: string;
  label: string;
}

export const langs: ILang[] = [
  {
    key: 'vi',
    image: 'image/lang-vi.png',
    label: 'Tiếng Việt',
  },
  {
    key: 'en',
    image: 'image/lang-en.jpg',
    label: 'English',
  },
];

const userLang = navigator.language;

const i18n = new I18n();
i18n.store(en);
i18n.store(vi);
i18n.defaultLocale = localStorage.getItem('lang') || userLang?.split('-')[0] || 'vi';
i18n.locale = localStorage.getItem('lang') || userLang?.split('-')[0] || 'vi';

export default i18n;
