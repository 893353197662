import { DatePicker } from 'antd';
import React from 'react';
import { CONTANTS } from 'src/utils';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof DatePicker>, IBaseFormItemProps {}

export function BaseDatePicker(props: IProps) {
  return (
    <BaseFormItem {...props}>
      <DatePicker
        {...props}
        size={props.size || 'large'}
        placeholder={props.placeholder || props.label}
        style={{ width: '100%' }}
        format={props.format || CONTANTS.DATE}
      />
    </BaseFormItem>
  );
}
