import { IBaseResponse, ICreateFileBody, IFile } from 'src/types';
import { http } from 'src/utils';

export default class FileService {
  url = '/files';

  public async upload(file: File, body: ICreateFileBody) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', process.env.REACT_APP_FOLDER_UPLOAD || '');

    Object.keys(body).forEach((i) => {
      if (body[i as keyof ICreateFileBody]) {
        formData.append(i, body[i as keyof ICreateFileBody] || '');
      }
    });

    const { data } = await http.post<IBaseResponse<IFile>>(this.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 1000 * 60 * 3,
    });

    return data.data;
  }

  public async delete(id: string) {
    const { data } = await http.delete<boolean>(`${this.url}/${id}`);
    return data;
  }
}
