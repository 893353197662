import { TreeSelect } from 'antd';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof TreeSelect>, IBaseFormItemProps {}

export function BaseTreeSelect(props: IProps) {
  return (
    <BaseFormItem {...props}>
      <TreeSelect
        treeCheckable
        {...props}
        showCheckedStrategy={props.showCheckedStrategy || 'SHOW_ALL'}
        placeholder={props.placeholder || props.label}
        size={props.size || 'large'}
      />
    </BaseFormItem>
  );
}
