import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { IDepartment } from 'src/types';
import { CONTANTS } from 'src/utils';

export const cols: any[] = [
  {
    key: 'name',
    fields: ['name'],
    render(_: any, record: IDepartment) {
      return <Link to={`${record.id}`}>{record.name}</Link>;
    },
    ellipsis: true,
    className: 'dragable',
  },
  {
    key: 'users',
    fields: ['users'],
    render(_: any, record: IDepartment) {
      return <span>{record.users.length}</span>;
    },
    ellipsis: true,
    className: 'dragable',
  },
  {
    key: 'date_created',
    fields: ['date_created'],
    render(_: any, record: IDepartment) {
      return <span>{dayjs(record.date_created).format(CONTANTS.DATE)}</span>;
    },
    ellipsis: true,
    className: 'dragable',
  },
];
