import { message } from 'src/stores/message';

export default class MESSAGE {
  static error(mess: string) {
    message.error(mess);
  }

  static success(mess: string) {
    message.success(mess);
  }

  static info(mess: string) {
    message.info(mess);
  }

  static warning(mess: string) {
    message.warning(mess);
  }
}
