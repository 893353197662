/* eslint-disable no-case-declarations */
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Spin } from 'antd';
import { memo, useEffect, useState } from 'react';
import lang from 'src/lang/lang';
import { BaseInput } from 'src/libraries';
import { useSocket } from 'src/providers/socket.provider';
import { ECollection, ItemService } from 'src/services';
import { useUser } from 'src/stores';
import { IConversation, IConversationBody, IUser } from 'src/types';
import { EVENTS } from 'src/utils';
import ListConversation from './ListConversation';
import ListUser from './ListUser';

interface IProps {
  onClose: () => void;
}

interface IState {
  isFocusedSearch: boolean;
  searchString?: string;
  conversations: IConversation[];
  loading: boolean;
}

const initState: IState = {
  isFocusedSearch: false,
  conversations: [],
  loading: true,
};

const svConversation = new ItemService<IConversation, IConversationBody, IConversationBody>(ECollection.conversation);

function ConversationPopover(props: IProps) {
  const [state, setState] = useState<IState>(initState);
  const { message, isAuth, onSubscribe, onUnSubscribe } = useSocket();
  const { user } = useUser();

  useEffect(() => {
    onSubscribe &&
      isAuth &&
      onSubscribe(ECollection.conversation, 'conversation', {
        filter: {
          users: {
            directus_users_id: { _eq: user?.id },
          },
        },
        fields: [
          'id',
          'name',
          'users.directus_users_id.id',
          'avatar',
          'date_last',
          'users.directus_users_id.first_name',
          'users.directus_users_id.last_name',
          'users.directus_users_id.avatar',
          'messages.date_read',
          'messages.id',
          'messages.user_created.id',
          'messages.file',
          'messages.content',
        ],
        deep: {
          messages: {
            _limit: 1,
            _sort: ['-date_created'],
          },
        },
        sort: ['-date_last'],
      });

    return () => {
      onUnSubscribe && onUnSubscribe('conversation');
    };
  }, [isAuth]);

  useEffect(() => {
    if (message?.uid !== 'conversation') return;
    switch (message?.event) {
      case 'init':
        const res = message.data as IConversation[];
        setState((prev) => ({ ...prev, conversations: res, loading: false }));

        break;
      case 'update':
        setState((prev) => ({
          ...prev,
          conversations: prev.conversations.map((i) => {
            if (i.id === message.data[0]?.id) {
              return message.data[0];
            }
            return i;
          }),
        }));

        break;

      default:
        break;
    }
  }, [message]);

  const handleOpenChat = (conversation: IConversation) => {
    EVENTS.onShowChatWindow(conversation);
    props.onClose();
  };

  const handleOpenChatUser = async (item: IUser) => {
    try {
      // check conversation existed
      const conversations = await svConversation.list({
        filter: {
          users: {
            _none: {
              directus_users_id: { _nin: [item.id, user?.id] },
            },
          },
        },
      });

      if (conversations.length > 0) {
        EVENTS.onShowChatWindow(conversations[0]);
      } else {
        const ids = [item.id, user?.id];
        const body: any = {
          name: `${item.first_name}${user?.first_name}`,
          users: {
            create: ids.map((id) => ({
              directus_users_id: id,
              role: 1,
            })),
          },
        };

        const res = await svConversation.create(body);

        EVENTS.onShowChatWindow(res);
      }
      props.onClose();
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <div className="custom-popover-content">
      <Row>
        {state.isFocusedSearch && (
          <ArrowLeftOutlined
            style={{ fontSize: 22, marginRight: 8 }}
            onClick={() => setState((prev) => ({ ...prev, isFocusedSearch: false }))}
          />
        )}

        <div style={{ flex: 1 }}>
          <BaseInput
            placeholder={lang.t('layout.find_user')}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                searchString: e.target.value,
              }));
            }}
            span={24}
            onFocus={() => setState((prev) => ({ ...prev, isFocusedSearch: true }))}
            // onBlur={handleBlur}
            noMessage
            showSearch
          />
        </div>
      </Row>

      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {state.isFocusedSearch ? (
          <ListUser keysearch={state.searchString} handleOpenChat={handleOpenChatUser} />
        ) : (
          <Spin spinning={state.loading}>
            <ListConversation data={state.conversations} handleOpenChat={handleOpenChat} />
          </Spin>
        )}
      </div>
    </div>
  );
}

export default memo(ConversationPopover);
