import { Key, ReactNode } from 'react';
import { IUser } from './user.type';

export interface IBaseList<T> {
  label: string;
  value?: T;
  id?: string;
  options?: IBaseList<T>[];
  disabled?: boolean;
}

export interface IBasePagingRes<T> {
  data: T[];
  total: number;
}

export interface IBaseProps {
  children: any;
}

export interface IBaseQuery {
  page?: number;
  limit?: number;
  search?: string;
  init?: boolean;
}

export interface IParams<T = {}> {
  fields?: string[];
  filter?: { [P in keyof T]?: IFilter & { [key: string]: any } } & { _or?: any; _and?: any };
  search?: string;
  sort?: string[];
  limit?: number;
  offset?: number;
  page?: number;
  aggregate?: IAggregateParam;
  groupBy?: string[];
  export?: EExportParam;
  meta?: string;
  deep?: any;
  alias?: any;
}

export interface IFilter {
  _eq?: any;
  _neq?: any;
  _lt?: any;
  _lte?: any;
  _gt?: any;
  _gte?: any;
  _in?: any;
  _nin?: any;
  _null?: any;
  _nnull?: any;
  _contains?: any;
  _icontains?: any;
  _ncontains?: any;
  _between?: any;
  _nbetween?: any;
  _some?: any;
  _any?: any;
  _none?: any;
}

export interface IAggregateParam {
  count?: string | string[];
  countDistinct?: string | string[];
  sum?: string | string[];
  sumDistinct?: string | string[];
  avg?: string | string[];
  avgDistinct?: string | string[];
  min?: string | string[];
  max?: string | string[];
  countAll?: string | string[];
}

export enum HttpStatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  ERROR_SERVER = 500,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  SUCCESS = 200,
}

export interface IBaseState<T> {
  loading: boolean;
  total: number;
  data: T[];
  refresh: boolean;
  page: number;
  detail?: T;
  selected: T[];
  filterDrawler: boolean;
  countFilter: number;
}

export interface IBaseConfirmModalParams {
  title?: string;
  content?: string;
  contentHtml?: string;
  onOk: () => Promise<boolean> | boolean;
}
export interface IBaseExportDrawlerParams {
  title?: string;
  onOk: (values: { type: EExportParam; count?: number }) => Promise<boolean>;
}
export interface IBaseImportDrawlerParams {
  title?: string;
  onOk: (file: File) => Promise<boolean>;
}

export interface IPropsIcon {
  size?: number;
  color?: string;
}

export enum LayoutType {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export enum LayoutColor {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface ILayout {
  type: LayoutType;
  primaryColor: string;
  bgColor: LayoutColor;
}

export interface IBreadcrumb {
  title: string;
  path: string;
}

export interface IBaseResponse<T> {
  [x: string]: any;
  data: T;
  message?: string;
  errors?: IErrorResponse[];
}

export interface IErrorResponse {
  message: string;
  extensions: { code: EErorCode };
}

export enum EErorCode {
  FORBIDDEN = 'FORBIDDEN',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  ECONNABORTED = 'ECONNABORTED',
}

export enum EExportParam {
  csv = 'csv',
  json = 'json',
  xml = 'xml',
  yaml = 'yaml',
}

export interface IBaseEntity {
  id: string;
  user_created?: IUser;
  user_updated?: IUser;
  date_created: string;
  date_updated?: string;
  count?: string;
  mark?: number;
  countDistinct?: {
    [key: string]: string;
  };
  sum?: {
    [key: string]: string;
  };
  sumDistinct?: {
    [key: string]: string;
  };
  avg?: {
    [key: string]: string;
  };
  avgDistinct?: {
    [key: string]: string;
  };
  // min?: {
  //   [key: string]: string;
  // };
  // max?: {
  //   [key: string]: string;
  // };
  countAll?: {
    [key: string]: string;
  };
}

export interface IParentChildEntity {
  id: string;
  parent_id: string;
  child_id: string;
}
export interface IParentChildBody {
  id?: string;
  parent_id?: string;
  child_id?: string;
}

export interface ITotalRes {
  countDistinct: { id: string };
}

export interface IFlattenOptionData<T> {
  data: T;
  group?: boolean;
  groupOption?: boolean;
  key: Key;
  label?: ReactNode;
  value?: any;
}

export interface IPinAction {
  label: string;
  eventHandler: () => void;
}

export interface IMapPin {
  center: {
    latitude: number;
    longitude: number;
  };
  options: {
    title?: string;
    icon?: string;
    color?: string;
    subTitle?: string;
    description?: string;
  };
  infobox?: {
    title: string;
    description?: string;
    showPointer?: boolean;
    showCloseButton?: boolean;
    actions?: IPinAction[];
  };
}

export interface IRelationBody<T> {
  create?: T[];
  update?: TypeRelationBody<T>[];
  delete?: (number | string)[];
}

export enum EService {
  debt = 'debt',
  schedule = 'schedule',
  rank = 'rank',
  achievement = 'achievement',
  document = 'document',
  subject = 'subject',
  news = 'news',
  task = 'task',
  attendance = 'attendance',
  shelf = 'shelf',
  wallet = 'wallet',
  payment = 'payment',
  product = 'product',
  product_category = 'product_category',
  property_category = 'property_category',
  wage_schedule = 'wage_schedule',
  wage_time = 'wage_time',
  wage_task = 'wage_task',
  wage_sell = 'wage_sell',
  wage_achievement = 'wage_achievement',
  wage = 'wage',
  session = 'session',
  voucher = 'voucher',
  return = 'return',
  sale = 'sale',
  checkout = 'checkout',
  turnback = 'turnback',
  report_unsold = 'report_unsold',
  report_inventory_book = 'report_inventory_book',
  report_revenue = 'report_revenue',
  role = 'role',
  staff = 'staff',
  customer = 'customer',
  message = 'message',
  shift = 'shift',
  storehouse = 'storehouse',
  room = 'room',
  table = 'table',
}

export interface IDataHasTreeMTM {
  id: string;
  parents: IParentChildEntity[];
  children: IParentChildEntity[];
  name: string;
}
export interface IDataHasTreeRTM {
  id: string;
  ranks: IParentChildEntity[];
  name: string;
}

export interface IDataHasTreeOTM {
  id: string;
  parent_id?: string;
  label?: string;
  children?: string[] | any[];
  name: string;
}

export type TypeRelationBody<T> =
  | ({
      [P in keyof T]?: T[P];
    } & { id?: string | number })
  | string;

export type TypeStatusColor = 'danger' | 'warning' | 'success' | 'secondary' | undefined;

export interface ILocation {
  type: 'Point';
  coordinates: IGeoJSONPoint;
}

interface IGeoJSONPoint extends Array<number | number> {
  0: number;
  1: number;
}

export enum EAddType {
  SELF = 'SELF',
  ALL = 'ALL',
  AFTER = 'AFTER',
}

export interface IAggregateRes<Field extends string | ''> extends Array<any> {
  0?: {
    count?: string;
    countDistinct?: {
      [key in Field]: number;
    };
    sum?: {
      [key in Field]: number;
    };
    sumDistinct?: {
      [key in Field]: number;
    };
    avg?: {
      [key in Field]: number;
    };
    avgDistinct?: {
      [key in Field]: number;
    };
    min?: {
      [key in Field]: number;
    };
    max?: {
      [key in Field]: number;
    };
    countAll?: {
      [key in Field]: number;
    };
  };
}

export interface IFee {
  name: string;
  cost: number;
  id?: string;
}

export interface ITranslation {
  content?: string;
  language_code: string;
  label?: string;
}

export interface IDataChart {
  x: string;
  count?: number;
  value?: number;
  name: string;
}
