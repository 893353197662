import { InputNumber } from 'antd';
import React from 'react';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof InputNumber>, IBaseFormItemProps {
  isInteger?: boolean;
  isPercent?: boolean;
}

export function BaseInputNumber(props: IProps) {
  return (
    <BaseFormItem {...props}>
      <InputNumber
        {...props}
        size={props.size || 'large'}
        placeholder={props.placeholder || props.label}
        formatter={(value) => {
          let valueFixed;
          if (props.isInteger) {
            valueFixed = Math.floor(Number(value));
          }
          if (props.isPercent) {
            return `${valueFixed || value}%`;
          }
          return `${valueFixed || value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }}
        parser={(value) => {
          if (props.isPercent) {
            return value?.replace('%', '') as unknown as number;
          }
          return value?.replace(/(,*)/g, '') ?? '';
        }}
        style={{ width: '100%' }}
        min={0}
      />
    </BaseFormItem>
  );
}
