import { IBaseEntity, IBaseQuery, IRelationBody } from './common.type';
import { IDebtPaymentBody } from './debt-payment.type';
import { IDebt } from './debt.type';
import { IDepartment } from './department.type';
import { IFinanceCategory } from './finance_category.type';
import { ITenant } from './tenant.type';

import { IUser } from './user.type';
import { IWallet } from './wallet.type';

export interface IPayment extends IBaseEntity {
  id: string;
  currency: EcurrencyType;
  value: number;
  wallet_id: IWallet;
  code: string;
  user_id: IUser;
  user_from_id: IUser;
  department_id: IDepartment;
  department_from_id: IDepartment;
  tenant_from_id: ITenant;
  wallet_from_id: IWallet;
  debts: IDebt[];
  checkouts: any[];
  wages: any[];
  tenant_id: ITenant;
  finance_category_id: IFinanceCategory;
  tenant_created: string;
  department_created: string;
  type: EPaymentType;
}

export enum EcurrencyType {
  VND = 'VND',
  USD = 'USD',
  EURO = 'EURO',
}

export enum sourceType {
  Tenant = 'Tenant',
  Department = 'Department',
  User = 'User',
  Individual = 'Individual',
}

export enum EPaymentType {
  RECEIVER = 'RECEIVER',
  EXPENSE = 'EXPENSE',
}

export interface IPaymentQuery extends IBaseQuery {
  code?: string;
  value?: string;
  department_id?: string;
  start?: string;
  end?: string;
  code_checkout_id?: string;
  finance_category_id?: string;
  code_return_id?: string;
  code_session_id?: string;
  code_turnback_id?: string;
  category?: string;
  limit: number;
  page: number;
  type?: EPaymentType;
}

export interface IPaymentBody {
  tenant_id?: string;
  tenant_from_id?: string;
  code?: string;
  currency?: EcurrencyType;
  value?: number;
  wallet_id?: string;
  wallet_from_id?: string;
  user_id?: string;
  user_from_id?: string;
  department_id?: string;
  department_from_id?: string;
  debts?: IRelationBody<IDebtPaymentBody>;
  tenant_created?: string;
  department_created?: string;
  finance_category_id?: string;
  content?: string;
  type?: EPaymentType;
}
