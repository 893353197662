import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface IUnreadSlice {
  notification: number;
  message: string[];
}

const initialState: IUnreadSlice = {
  notification: 0,
  message: [],
};

const unreadSlice = createSlice<IUnreadSlice, SliceCaseReducers<IUnreadSlice>>({
  name: 'unread',
  initialState,
  reducers: {
    setUnread: (state, { payload }: PayloadAction<IUnreadSlice>) => {
      // if (state) {
      //   return {
      //     ...state,
      //     ...payload,
      //   };
      // }
      return payload;
    },
  },
});

export const { setUnread } = unreadSlice.actions;

export default unreadSlice.reducer;
