import { Col, Form } from 'antd';
import { IBaseProps } from 'src/types';
import { IBaseFormItemProps } from './form.type';

interface IProps extends IBaseProps, IBaseFormItemProps {}

export function BaseFormItem(props: IProps) {
  return (
    <Col span={props.span || 6}>
      <Form.Item
        label={props.label}
        name={props.name}
        rules={props.rules}
        required={props.required}
        className={props.formItemClassName}
        style={{ marginBottom: props.noMessage ? 0 : 24 }}
      >
        {props.children}
      </Form.Item>
    </Col>
  );
}
