import { memo, useEffect, useState } from 'react';
import lang from 'src/lang/lang';
import { SpinnerLoader } from 'src/libraries';
import { ECollection, ItemService, UserService } from 'src/services';
import { useDepartment, useRefresh, useTenant, useUser } from 'src/stores';
import { EDepartmentType, ERoleLevel, IBaseProps, IDepartment, ITenant } from 'src/types';

const svUser = new UserService();
const svTenant = new ItemService<ITenant>(ECollection.tenant);

function InitAppProvider({ children }: IBaseProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { changeUser } = useUser();
  const { changeTenant } = useTenant();
  const { changeDepartment } = useDepartment();
  const { refresh } = useRefresh();

  useEffect(() => {
    fetchInit();
  }, [refresh]);

  const fetchInit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const user = await svUser.me({
        fields: [
          'id',
          'tenant_id',
          'avatar',
          'email',
          'first_name',
          'last_name',
          'role.id',
          'role.name',
          'role.level',
          'role.policies.policy.name',
          'departments.id',
          'departments.department_id.id',
          'departments.department_id.name',
          'departments.department_id.parents.id',
          'departments.department_id.parents.parent_id',
        ],
      });

      if (user) {
        // Lưu thông tin user vào redux
        changeUser(user);
        // Lấy thông tin tenant
        if (user.tenant_id) {
          let fields = [
            'id',
            'name',
            'layout',
            'theme',
            'color',
            'image',
            'product_stock',
            'product_serial',
            'department',
            'social',
            'shipping',
            'offline',
            'language',
            'phone',
            'email',
            'department_type',
            'tenant_category_id',
            'address.id',
            'address.phone',
            'address.detail',
            'address.nation_id',
            'address.province_id',
            'address.district_id',
            'address.ward_id',
            'settings.id',
            'settings.label',
            'settings.icon',
            'settings.menu',
            'settings.fields',
            'settings.is_group_menu',
            'settings.is_department',
            'settings.parents.id',
            'settings.parents.parent_id',
            'settings.parents.child_id',
            'settings.children.id',
            'settings.children.parent_id',
            'settings.children.child_id',
            'settings.services.id',
            'settings.services.service_id.name',
            'settings.services.service_id.id',
            'settings.service_id.name',
            'settings.service_id.id',
            'departments.id',
            'departments.name',
            'departments.parents.id',
            'departments.parents.parent_id',
            'departments.parents.child_id',
            'social_shops.id',
            'social_shops.name',
          ];

          const deep: any = {
            settings: {
              services: {
                _sort: 'priority',
              },
              _sort: ['-is_department', 'menu'],
              _filter: {
                // ẩn thành tích

                _or: [
                  {
                    menu_roles: {
                      directus_roles_id: {
                        _eq: user.role?.id,
                      },
                    },
                    service_id: {
                      _nin: ['3fcecb3c-e86f-486c-ad69-c5a4f53655c8', '19a59aab-101c-4b4a-858f-f5d01f5c63ec'],
                    },
                  },
                  {
                    service_id: {
                      _null: true,
                    },
                  },
                  {
                    is_group_menu: true,
                  },
                ],
              },
            },
          };

          if (
            user.role.level === ERoleLevel.TENANT &&
            user.role.policies.some((i) => i.policy.name === 'tenant editor')
          ) {
            fields = [
              ...fields,
              ...[
                'sepay_token',
                'bank_code',
                'bank_account',
                'licenses.id',
                'licenses.license_package_id.name',
                'licenses.end',
              ],
            ];

            deep.licenses = {
              _limit: 1,
              _sort: ['-end'],
            };
          }

          const tenant = await svTenant.detail(user.tenant_id, {
            fields,
            deep,
          });
          changeTenant(tenant);

          changeDepartment({
            departments:
              user.role.level === ERoleLevel.TENANT
                ? tenant.departments
                : user.departments.map((i) => i.department_id as IDepartment),
            myDepartments: user.departments.map((i) => i.department_id as IDepartment),
            allDepartments: tenant.departments,
            label:
              tenant.department_type === EDepartmentType.BRANCH
                ? lang.t('library.branch')
                : lang.t('voucher.department'),
          });

          localStorage.setItem('settings', JSON.stringify(tenant.settings));
          localStorage.setItem('tenant_lang', tenant.language);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  if (loading) return <SpinnerLoader bg={false} />;

  return children;
}

export default memo(InitAppProvider);
