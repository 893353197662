import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/stores';
import { setLang } from './lang.slice';

export default function useLang() {
  const dispatch = useDispatch();
  const { locale } = useAppSelector((state) => state.lang);

  const changeLocale = (locale: string) => {
    dispatch(setLang({ locale }));
  };

  return {
    locale,
    // changeLayout,
    changeLocale,
  };
}
