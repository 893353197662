import { IBaseEntity } from './common.type';
import { EProductEntityStatus, IProductEntity } from './product_entity.type';
import { IShelf } from './shelf.type';

export interface IProductEntityHistory extends IBaseEntity {
  pack_id?: string;
  order_id?: string;
  shelf_id?: IShelf;
  tenant_id: string;
  turnback_product_id?: string;
  product_entity_id: IProductEntity;
  status: EProductEntityStatus;
  action: EProductEntityHistoryAction;
  date_action: string;
}

export interface IProductEntityHistoryBody {
  pack_id?: string;
  order_id?: string;
  shelf_id?: string;
  tenant_id?: string;
  product_entity_id?: string;
  status?: EProductEntityStatus;
  action?: EProductEntityHistoryAction;
  turnback_product_id?: string;
  return_product_id?: string;
  date_action?: string;
}

export enum EProductEntityHistoryAction {
  IMPORT = 1,
  EXPORT = 2,
}
