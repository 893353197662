import { IBaseEntity, IBaseQuery, IRelationBody, TypeRelationBody } from './common.type';
import { IFile, IFileBody } from './file.type';
import { EScheduleMode, ISchedule } from './schedule.type';
import { IShelf } from './shelf.type';
import { ETaskType, ITask } from './task.type';
import { ITaskGroup, ITaskGroupBody } from './task_group.type';
import { ITaskRole, ITaskRoleBody } from './task_role.type';

export interface ITaskSchedule extends IBaseEntity {
  task_id: ITask;
  schedule_id: ISchedule;
  start: string;
  end: string;
  status: ITaskScheduleStatus[];
  status_current: ETaskScheduleStatus;
  roles: ITaskRole[];
  task_groups: ITaskGroup[];
  parent_id: ITaskSchedule;
  children: ITaskSchedule[];
  name: string;
  mode: EScheduleMode;
  content: string;
  // documents: [];
  files: IFile[];
  coefficient: number;
  shelf_id: IShelf;
  type: ETaskType;
}

export interface ITaskScheduleQuery extends IBaseQuery {
  start?: string;
  end?: string;
  name?: string;
}

export interface ITaskScheduleBody {
  id?: string;
  status?: ITaskScheduleStatus[];
  status_current?: ETaskScheduleStatus;
  schedule_id?: string | TypeRelationBody<ISchedule>;
  task_id?: string;
  start?: string;
  end?: string;
  roles?: IRelationBody<ITaskRoleBody>;
  task_groups?: IRelationBody<ITaskGroupBody>;
  parent_id?: string;
  children?: IRelationBody<ITaskScheduleBody>;
  name?: string;
  mode?: EScheduleMode;
  content?: string;
  // documents: [];
  files?: IRelationBody<IFileBody>;
  coefficient?: number;
  shelf_id?: string;
  type?: ETaskType;
}

export interface ITaskScheduleStatus {
  date: string;
  status: ETaskScheduleStatus;
}

export enum ETaskScheduleStatus {
  SUCCESS,
  FAIL,
}
