import { createContext, memo, useContext, useEffect, useMemo, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { ECollection } from 'src/services';
import { IBaseProps, IParams } from 'src/types';
import { refreshToken } from 'src/utils/http';

const hostSocket = process.env.REACT_APP_WEBSOCKET_URL || `wss://api-business.teli.vn/websocket`;

type SocketEvent = 'init' | 'create' | 'update' | 'delete';

interface IWebSocket {
  onSubscribe?: (
    collection: ECollection,
    uid?: string,
    query?: IParams<any>,
    event?: SocketEvent | SocketEvent[],
  ) => void;
  message?: IMessage;
  isAuth: boolean;
  onUnSubscribe?: (uid: string) => void;
}

interface IMessage {
  type: 'subscribe' | 'subscription' | 'auth' | 'ping' | 'pong';
  collection?: ECollection;
  event?: SocketEvent;
  data?: any;
  query?: IParams<any>;
  uid?: string;
  status?: 'ok' | 'error';
}

const defaultSocket: IWebSocket = { isAuth: false };

const SocketContext = createContext(defaultSocket);

export const useSocket = () => useContext(SocketContext);

function SocketProvider({ children }: IBaseProps) {
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const [message, setMessage] = useState<IMessage>();
  const webSocket = useWebSocket(`${hostSocket}?access_token=${localStorage.getItem('accesstoken')}`);

  useEffect(() => {
    return () => {
      if (webSocket) {
        webSocket.getWebSocket()?.close();
      }
    };
  }, []);

  useEffect(() => {
    const message = webSocket.lastJsonMessage as IMessage;

    if (!message) return;
    switch (message.type) {
      case 'auth':
        if (message.status === 'ok') {
          setIsAuth(true);
        }
        if (message.status === 'error') {
          setIsAuth(false);
          // TODO: refresh token
          onAuthenticate();
        }
        break;
      case 'ping':
        onPong();
        break;
      case 'subscription':
        setMessage(message);
        break;
      default:
        break;
    }
  }, [webSocket.lastJsonMessage]);

  useEffect(() => {
    switch (webSocket.readyState) {
      case ReadyState.CONNECTING:
        console.log('Socket connecting!');
        break;
      case ReadyState.OPEN:
        console.log('Socket opened!');
        // onAuthenticate();
        break;
      case ReadyState.CLOSING:
        console.log('Socket closing!');
        break;
      case ReadyState.UNINSTANTIATED:
        console.log('Socket uninstall!');
        break;
      case ReadyState.CLOSED:
        console.log('Socket closed!');
        setIsAuth(false);
        break;

      default:
        break;
    }
  }, [webSocket.readyState]);

  const onSubscribe = (
    collection: ECollection,
    uid?: string,
    query?: IParams<any>,
    event?: SocketEvent | SocketEvent[],
  ) => {
    if (!isAuth) {
      console.log('Chưa đăng nhập');
      return;
    }
    webSocket.sendJsonMessage({
      type: 'subscribe',
      collection,
      query,
      uid,
      event,
    });
  };

  const onUnSubscribe = (uid: string) => {
    webSocket.sendJsonMessage({
      type: 'unsubscribe',
      uid,
    });
  };

  const onAuthenticate = async () => {
    await refreshToken();
    webSocket.sendJsonMessage({
      type: 'auth',
      access_token: localStorage.getItem('accesstoken'),
    });
  };

  const onPong = () => {
    webSocket.sendJsonMessage({
      type: 'pong',
    });
  };

  const context = useMemo<IWebSocket>(() => {
    return {
      onSubscribe,
      message,
      isAuth,
      onUnSubscribe,
    };
  }, [isAuth, message]);

  return <SocketContext.Provider value={context}>{children}</SocketContext.Provider>;
}

export default memo(SocketProvider);
