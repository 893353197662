import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/stores';
import { setUnread } from './unread.slice';

export default function useUnread() {
  const dispatch = useDispatch();
  const unread = useAppSelector((state) => state.unread);

  const changeUnreadMessage = (data: string[]) => {
    dispatch(setUnread({ ...unread, message: data }));
  };

  const changeUnreadNotification = (data: number) => {
    dispatch(setUnread({ ...unread, notification: data }));
  };

  return {
    changeUnreadMessage,
    changeUnreadNotification,
    unreadMessage: unread.message,
    unreadNotification: unread.notification,
  };
}
