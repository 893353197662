/* eslint-disable no-case-declarations */
import { BellFilled } from '@ant-design/icons';
import { Badge, Popover } from 'antd';
import { memo, useEffect, useState } from 'react';
import lang from 'src/lang/lang';
import { useSocket } from 'src/providers/socket.provider';
import { ECollection } from 'src/services';
import { useUnread, useUser } from 'src/stores';
import { INotification } from 'src/types';
import NotificationPopover from './NotificationPopover';

function Notification() {
  const [open, setOpen] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<INotification>();
  const { message, isAuth, onSubscribe, onUnSubscribe } = useSocket();
  const { user } = useUser();
  const { changeUnreadNotification, unreadNotification } = useUnread();

  useEffect(() => {
    onSubscribe &&
      isAuth &&
      onSubscribe(
        ECollection.directus_notifications,
        'new_notification',
        {
          filter: {
            recipient: { _eq: user?.id },
            // collection: { _neq: ECollection.conversation },
          },
          fields: [
            'id',
            'subject',
            'message',
            'status',
            'collection',
            'item',
            'sender.first_name',
            'sender.last_name',
            'sender.avatar',
            'timestamp',
          ],
        },
        'create',
      );

    return () => {
      onUnSubscribe && onUnSubscribe('new_notification');
    };
  }, [isAuth]);

  useEffect(() => {
    if (message?.uid !== 'new_notification') return;
    switch (message?.event) {
      case 'create':
        const countNewNoti = message.data.length;
        setNewItem(message.data[0]);
        !open && changeUnreadNotification(unreadNotification + countNewNoti);

        break;

      default:
        break;
    }
  }, [message]);

  return (
    <Popover
      placement="bottomRight"
      title={<b style={{ fontSize: '18px' }}>{lang.t('layout.notification')}</b>}
      trigger="click"
      content={<NotificationPopover newItem={newItem} onClose={() => setOpen(false)} />}
      onOpenChange={(open) => {
        setOpen(open);
        open && changeUnreadNotification(0);
      }}
      arrow={false}
      overlayClassName="custom-popover"
      open={open}
    >
      <Badge count={unreadNotification} offset={[-12, 8]}>
        <div className="icon-wrapper">
          <BellFilled style={{ color: 'var(--colorNormal)', fontSize: 22 }} />
        </div>
      </Badge>
    </Popover>
  );
}

export default memo(Notification);
