import { Divider, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { EPropertyCategoryType, IPropertyCategory } from 'src/types';
import { CONTANTS } from 'src/utils';
import { IPropertyValue } from './PropertyInput';

export const renderContent = (propertyCategory: IPropertyCategory, item: IPropertyValue) => {
  switch (propertyCategory.type) {
    case EPropertyCategoryType.STRING:
      return (
        <span>
          {item.value} <Divider type="vertical" /> <Typography.Text type="secondary">{item.label}</Typography.Text>
        </span>
      );
    case EPropertyCategoryType.NUMBER:
      return (
        <span>
          {item.value} <Divider type="vertical" /> <Typography.Text type="secondary">{item.label}</Typography.Text>
        </span>
      );
    case EPropertyCategoryType.COLOR:
      return (
        <Space>
          <div style={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: item.value }} />
          <Divider type="vertical" />
          <Typography.Text type="secondary">{item.label}</Typography.Text>
        </Space>
      );
    case EPropertyCategoryType.TEXT:
      return <span>{item.value}</span>;
    case EPropertyCategoryType.TIME:
      return (
        <span>
          {dayjs(item.value).format(CONTANTS.DATE)} <Divider type="vertical" />{' '}
          <Typography.Text type="secondary">{item.label}</Typography.Text>
        </span>
      );
    case EPropertyCategoryType.LOCATION:
      return (
        <span>
          {item.value} <Divider type="vertical" /> <Typography.Text type="secondary">{item.label}</Typography.Text>
        </span>
      );
    default:
      break;
  }

  return null;
};
