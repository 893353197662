import { ICheckout } from './checkout.type';
import { IBaseEntity, IBaseQuery, IRelationBody } from './common.type';
import { IDebtPayment, IDebtPaymentBody } from './debt-payment.type';
import { IFinanceCategory } from './finance_category.type';
import { IReturn } from './return.type';
import { ISession } from './session.type';
import { ITurnback } from './turnback.type';

export interface IDebt extends IBaseEntity {
  code?: string;
  value: number;
  tenant_from_id: string;
  tenant_id: string;
  department_id: string;
  department_from_id: string;
  user_id: string;
  user_from_id: string;
  session_id: ISession;
  checkout_id: ICheckout;
  payments: IDebtPayment[];
  content: string;
  is_fee: boolean;
  turnback_id: ITurnback;
  return_id: IReturn;
  debt_id: IDebt;
  department_created?: string;
  tenant_created?: string;
  type: EDebtType;
  finance_category_id: IFinanceCategory | string;
}

export interface IDebtQuery extends IBaseQuery {
  code?: string;
  start?: string;
  end?: string;
  department_ids?: string[];
  type?: string;
  finance_category_id?: string;
}

export interface IDebtBody {
  code?: string;
  value?: number;
  tenant_from_id?: string;
  tenant_id?: string;
  department_id?: string;
  department_from_id?: string;
  user_id?: string;
  user_from_id?: string;
  session_id?: string;
  checkout_id?: string;
  turnback_id?: string;
  payments?: IRelationBody<IDebtPaymentBody>;
  content?: string;
  is_fee?: boolean;
  finance_category_id?: string;
  department_created?: string;
  tenant_created?: string;
  type?: EDebtType;
}

export enum EDebtType {
  RECEIVER = 'RECEIVER',
  EXPENSE = 'EXPENSE',
}
