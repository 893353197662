export interface IBaseEvent {
  type: EventBusName;
}

export interface IBaseEventPayload<Payload> {
  type: EventBusName;
  payload: Payload;
}

export enum EventBusName {
  SHOW_MODAL_CONFIRM,
  SHOW_MODAL_ADD_TYPE,
  SHOW_DRAWLER_EXPORT,
  SHOW_DRAWLER_IMPORT,
  ERROR,
  SHOW_CHAT_WINDOW,
}
