import BingMapsReact from 'bingmaps-react';
import { memo, useEffect, useMemo, useState } from 'react';
import images from 'src/assets/image/images';
import lang from 'src/lang/lang';

interface IState {
  latitude?: number;
  longitude?: number;
  zoom: number;
}

interface IProps {
  data?: { lat: number; lng: number; label?: string };
  size?: number;
  onChange: (lat?: number, lng?: number) => void;
  zoom?: number;
}

function BaseMap({ data, size, onChange, zoom = 10 }: IProps) {
  const [state, setState] = useState<IState>({
    zoom,
    latitude: data?.lat,
    longitude: data?.lng,
  });
  const [map, setMap] = useState<any>();

  useEffect(() => {
    // @ts-ignore
    if (window?.Microsoft?.Maps?.Events && map) {
      // @ts-ignore
      const Maps: any = window.Microsoft.Maps;

      Maps.Events.addHandler(map, 'click', (event: any) => {
        const location = event?.location;

        if (location) {
          setState((prev) => ({
            ...prev,
            latitude: location.latitude,
            longitude: location.longitude,
            // showMarker: true,
          }));
        }
      });
    }
    // @ts-ignore
  }, [window?.Microsoft?.Maps?.Events, map]);

  useEffect(() => {
    onChange(state.latitude, state.longitude);
  }, [state.latitude, state.longitude]);

  const onMapReady = ({ map }: any) => {
    setMap(map.current);
  };

  const viewOptions = useMemo(() => {
    if (state.latitude !== undefined && state.longitude !== undefined) {
      return {
        center: {
          latitude: state.latitude,
          longitude: state.longitude,
        },
        mapTypeId: 'grayscale',
        zoom,
      };
    }

    return {
      center: {
        latitude: 21,
        longitude: 105,
      },
      mapTypeId: 'grayscale',
      zoom,
    };
  }, [state.latitude, state.longitude]);

  const pushPins = useMemo(() => {
    return [
      {
        center: {
          latitude: state.latitude,
          longitude: state.longitude,
        },
        options: {
          icon: images.icLocation,
        },
        infobox: {
          title: lang.t('library.current_location'),
        },
      },
    ];
  }, [state.latitude, state.longitude]);

  return (
    <div style={{ height: size || 300, width: size || 300 }}>
      <BingMapsReact
        viewOptions={viewOptions}
        zoom={zoom || 3}
        onMapReady={onMapReady}
        bingMapsKey={process.env.REACT_APP_MAP_API_KEY}
        height={`${size || 300}px`}
        pushPinsWithInfoboxes={pushPins}
      />
    </div>
  );
}

export default memo(BaseMap);
