import { IAddress, IAddressBody } from './address.type';
import { IBaseEntity, IRelationBody, LayoutColor, LayoutType } from './common.type';
import { IDepartment } from './department.type';
import { ILicense } from './license.type';
import { ISetting } from './setting.type';
import { ISocialShop } from './social_shop.type';

export interface ITenant extends IBaseEntity {
  color: string;
  font: string;
  image: string;
  name: string;
  settings: ISetting[];
  layout: LayoutType;
  theme: LayoutColor;
  departments: IDepartment[];
  private: boolean;
  social_shops: ISocialShop[];
  social: boolean;
  product_stock: boolean;
  product_serial: boolean;
  department: boolean;
  shipping: boolean;
  offline: boolean;
  phone: string;
  email: string;
  address: IAddress[];
  department_type: EDepartmentType;
  tenant_category_id: string;
  sepay_token: string;
  bank_code: string;
  bank_account: string;
  language: string;
  licenses: ILicense[];
}

export interface ITenantBody {
  color?: string;
  font?: string;
  image?: string;
  name?: string;
  // settings?: IRelationBody<ISettingBody>;
  layout?: LayoutType;
  theme?: LayoutColor;
  phone?: string;
  email?: string;
  address?: IRelationBody<IAddressBody>;

  bank_code?: string;
  bank_account?: string;
}

export enum EDepartmentType {
  DEPARTMENT = 'DEPARTMENT',
  BRANCH = 'BRANCH',
}
