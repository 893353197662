import {
  IBaseResponse,
  IReportInventoryBook,
  IReportInventoryBookQuery,
  IReportUnsold,
  IReportUnsoldQuery,
  IReportUnsoldTotal,
} from 'src/types';
import { http } from 'src/utils';

export default class FlowService {
  url = '/flows/trigger';

  public async calcCostProduct(product_id: string) {
    const { data } = await http.post<IBaseResponse<any>>(`${this.url}/6548accc-c3e1-4d98-95c1-51ec996e6ea8`, {
      product_id,
    });
    return data;
  }

  public async reportUnsold(query: IReportUnsoldQuery) {
    const { data } = await http.get<IReportUnsold[]>(`${this.url}/06e82817-eb06-42aa-abde-eb24ff7d13e0`, {
      params: query,
    });
    return data;
  }

  public async reportUnsoldTotal(query: IReportUnsoldQuery) {
    const { data } = await http.get<IReportUnsoldTotal>(`${this.url}/44ada7d2-b58a-4598-9c51-dd4f22779381`, {
      params: query,
    });
    return data;
  }

  public async reportInventoryBook(query: IReportInventoryBookQuery) {
    const { data } = await http.get<IReportInventoryBook[]>(`${this.url}/feaaa929-2588-4b85-a38a-2579f6cd41c5`, {
      params: query,
    });
    return data;
  }

  public async getFeeGHTK(body: {
    delivery_brand_id: string;
    tenant_id?: string;
    pick_province: string;
    pick_district: string;
    pick_ward?: string;
    pick_address?: string;
    province?: string;
    district?: string;
    ward?: string;
    address?: string;
    deliver_option: 'xteam' | 'none';
    weight: number;
  }) {
    const { data } = await http.post<number>(`${this.url}/90c4689d-ab0b-48d7-adbd-788a0522cfae`, body);
    return data;
  }

  public async createTenant(body: {
    name: string;
    phone: string;
    email: string;
    nation_id?: string;
    province_id?: string;
    district_id?: string;
    ward_id?: string;
    detail?: string;
    tenant_category_id: string;
    lang: string;
    shipping: boolean;
    offline: boolean;
    branch: boolean;
    department: boolean;
  }) {
    const { data } = await http.post<any>(`${this.url}/765de75a-d83f-4320-921f-961894d5b96a`, body);
    return data;
  }

  public async fetchSocialProduct(query: { id: number }) {
    const { data } = await http.get<any>(`${this.url}/332e2664-65fb-4eec-a422-ba17e1b2387d`, { params: query });
    return data;
  }

  public async syncProduct(body: { products: { id: string; cost: number }[]; shop_id: number; shelf_id?: string }) {
    const { data } = await http.post<any>(`${this.url}/888de605-5550-4f24-bd00-56b5d088993a`, body);
    return data;
  }

  public async printDelivery(query: {
    delivery_history_id: string;
    original: 'portrait' | 'landscape';
    page_size: 'A5' | 'A6';
  }) {
    const { data } = await http.get<any>(`${this.url}/9224fba4-9964-42df-8490-8dc310efcba2`, {
      params: query,
    });
    return data;
  }

  public async cancelDelivery(id: string) {
    const { data } = await http.get<any>(`${this.url}/aec68426-23de-4862-931d-84577310c867`, {
      params: { id },
    });
    return data;
  }
}
