import { Skeleton } from 'antd';
import { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface IProps {
  hasMore: boolean;
  dataLength: number;
  currentPage?: number;
  fetchMoreData: any;
  scrollableTarget?: any;
  isCustomScroll?: boolean;
  children: any;
  inverse?: boolean;
  customStyle?: any;
  height?: number;
  extraClassName?: string;
  onScroll?: () => void;
}

export default function BaseInfinityScroll(props: IProps) {
  const {
    hasMore,
    dataLength,
    fetchMoreData,
    scrollableTarget,
    isCustomScroll,
    inverse,
    customStyle,
    height,
    extraClassName,
    onScroll,
    currentPage,
  } = props;
  const isDiv = useRef(null);

  const next = () => {
    if (isCustomScroll) return;
    fetchMoreData((currentPage || 0) + 1);
  };

  return (
    <InfiniteScroll
      ref={isDiv}
      next={next}
      dataLength={dataLength}
      hasMore={hasMore}
      scrollableTarget={scrollableTarget}
      pullDownToRefreshThreshold={80}
      inverse={inverse}
      loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
      style={customStyle}
      height={height}
      className={extraClassName}
      onScroll={onScroll}
    >
      {props.children}
    </InfiniteScroll>
  );
}
