import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { memo, useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import lang from 'src/lang/lang';
import { IBaseConfirmModalParams } from 'src/types';
import { EventBusName } from 'src/utils/event-bus';
import EventBus from 'src/utils/event-bus/event-bus';

function ModalConfirm() {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<IBaseConfirmModalParams>();

  const subscriptions = useRef<any>();

  useEffect(() => {
    registerEventBus();

    return () => {
      unregisterEventBus();
    };
  }, []);

  const registerEventBus = () => {
    subscriptions.current = new Subscription();
    subscriptions.current.add(
      EventBus.getInstance().events.subscribe((data: any) => {
        switch (data.type) {
          case EventBusName.SHOW_MODAL_CONFIRM:
            handleShowModal(data.payload);
            break;
          default:
            break;
        }
      }),
    );
  };

  const handleShowModal = (payload: IBaseConfirmModalParams) => {
    setParams(payload);
    setVisible(true);
  };

  const unregisterEventBus = () => {
    subscriptions.current?.unsubscribe();
  };

  const handleOk = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await params?.onOk();
      if (res) {
        handleCancel();
      }
    } catch (error) {
      // merror(error.response.data);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setLoading(false);
    setParams(undefined);
  };

  const renderDescription = (): any => {
    if (params?.contentHtml) {
      return <div dangerouslySetInnerHTML={{ __html: params?.contentHtml }} />;
    }

    return params?.content;
  };

  return (
    <Modal
      open={!!params && visible}
      title={params?.title}
      onOk={handleOk}
      onCancel={handleCancel}
      width={500}
      zIndex={9999}
      maskClosable={false}
      footer={[
        <Button size="large" key="back" ghost type="primary" onClick={handleCancel}>
          {lang.t('default.cancel')}
        </Button>,
        <Button size="large" key="submit" type="primary" loading={loading} onClick={handleOk}>
          {lang.t('default.confirm')}
        </Button>,
      ]}
    >
      <div style={{ margin: '20px 0px' }}>{renderDescription()}</div>
    </Modal>
  );
}

export default memo(ModalConfirm);
