import { ECollection } from 'src/services';
import { IUser } from './user.type';

export interface INotification {
  id: number;
  timestamp: string;
  status: string;
  recipient: string | IUser;
  sender: string | IUser;
  subject: string;
  message: string;
  collection: ECollection;
  item: string;
}

export interface INotificationBody {
  status?: string;
  recipient?: string | IUser;
  sender?: string | IUser;
  subject?: string;
  message?: string;
  collection?: ECollection;
  item?: string;
}

export enum ENotificationStatus {
  inbox = 'inbox',
  archived = 'archived',
  seen = 'seen',
}
