import { IBaseEntity, IBaseQuery } from './common.type';
import { ILicense } from './license.type';
import { ITenantCategory } from './tenant_category.type';

export interface ILicensePackage extends IBaseEntity {
  name: string;
  period: ELicensePackagePeriod;
  currency: string;
  max: number;
  max_checkout: number;
  price: number;
  licenses: ILicense[];
  tenant_categories: { tenant_category_id: ITenantCategory; id: number }[];
  level: number;
  vn_price: number;
}

export interface ILicensePackageQuery extends IBaseQuery {}

export enum ELicensePackagePeriod {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
