import { IBaseEntity, IBaseQuery, IRelationBody } from './common.type';
import { IFile, IFileBody } from './file.type';
import { EScheduleMode } from './schedule.type';
import { IShelf } from './shelf.type';
import { IShift } from './shift.type';
import { ITaskDepartment, ITaskDepartmentBody } from './task-department.type';
import { ITaskDocumentBody } from './task-document.type';
import { ITaskSchedule, ITaskScheduleBody } from './task-schedule.type';
import { ITaskGroup, ITaskGroupBody } from './task_group.type';
import { ITaskRole, ITaskRoleBody } from './task_role.type';

export interface ITask extends IBaseEntity {
  description: string | TrustedHTML;
  name: string;
  parent_id: string;
  children: ITask[];
  departments: ITaskDepartment[];
  mode: EScheduleMode;
  content: string;
  tenant_id?: string;
  // documents: [];
  // wage_schedules: [];
  files: IFile[];
  shift_id: IShift;
  coefficient: number;
  children_condition: boolean;
  task_groups: ITaskGroup[];
  roles: ITaskRole[];
  shelf_id: IShelf;
  type: ETaskType;
  start?: number;
  duration?: number;
  schedules: ITaskSchedule[];
}

export interface ITaskQuery extends IBaseQuery {
  name?: string;
  mode?: EScheduleMode;
  department_ids?: string[];
  shelf_ids?: string[];
  parent_ids?: string[];
}

export interface ITaskBody {
  name: string;
  // start?: string;
  // end?: string;
  parent_id?: string;
  children?: IRelationBody<ITaskBody>;
  departments?: IRelationBody<ITaskDepartmentBody>;
  mode?: EScheduleMode;
  content?: string;
  type?: ETaskType;
  documents?: IRelationBody<ITaskDocumentBody>;
  // wage_schedules: [];
  files?: IRelationBody<IFileBody>;
  shift_id?: string;
  shelf_id?: string;
  coefficient?: number;
  children_condition?: boolean;
  task_groups?: IRelationBody<ITaskGroupBody>;
  roles?: IRelationBody<ITaskRoleBody>;
  start?: number;
  duration?: number;
  schedules?: IRelationBody<ITaskScheduleBody>;
  tenant_id?: string;
}

export interface ICreateTaskBody extends ITaskBody {}
export interface IUpdateTaskBody extends ITaskBody {}

export enum ETaskType {
  MARK = 'MARK',
  TASK = 'TASK',
}
