/* eslint-disable react/jsx-no-useless-fragment */
import { Empty } from 'antd';
import lang from 'src/lang/lang';
import { useUser } from 'src/stores';
import { IConversation } from 'src/types/conversation.type';
import { FUNCS } from 'src/utils';
import TimeAgo from 'timeago-react';

interface IProps {
  data: IConversation[];
  handleOpenChat: (conversation: IConversation) => void;
}

function ListConversation(props: IProps) {
  const { user } = useUser();

  return (
    <>
      {props.data.length > 0 ? (
        <div className="list-chat">
          {props.data.map((item, index) => {
            const unSeen = item?.messages[0].user_created?.id !== user?.id && !item?.messages[0].date_read;

            const userConversation = item.users?.find((e) => e.directus_users_id.id !== user?.id)?.directus_users_id;

            const avatar = item.users.length > 2 ? item.avatar : userConversation?.avatar;
            const title = item.users.length > 2 ? item.name : FUNCS.getFullName(userConversation);

            return (
              <div key={index} className="chat-item" onClick={() => props.handleOpenChat(item)}>
                <div className="profile-pic">
                  <img src={FUNCS.getFullMedialUrl(avatar)} alt="avatar" />
                </div>
                <div className="chat-details">
                  <div className="chat-name">{title}</div>
                  <div
                    className="chat-message-converstation"
                    style={{
                      color: unSeen ? '#020' : '#b0b3b8',
                      fontWeight: unSeen ? 'bold' : '',
                    }}
                  >
                    {item.messages[0]?.user_created?.id === user?.id && `${lang.t('layout.you')}:`}
                    {item.messages[0]?.file
                      ? lang.t('dashboard.picture')
                      : FUNCS.truncateString(item.messages[0]?.content, 20) || lang.t('layout.no_message')}{' '}
                    · <TimeAgo datetime={item.date_last} locale="vi" />
                  </div>
                </div>
                <div className="chat-time">
                  {unSeen && (
                    <img
                      src="image/new-moon.png"
                      style={{
                        marginBottom: '7px',
                        marginTop: '7px',
                        marginRight: '10px',
                        width: '15px',
                        height: '15px',
                        cursor: 'pointer',
                      }}
                      alt="avatar"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
}

export default ListConversation;
