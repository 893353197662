import { CheckOutlined } from '@ant-design/icons';
import { Button, Drawer, Tooltip } from 'antd';
import { memo } from 'react';
import lang from 'src/lang/lang';
import { IBaseProps } from 'src/types';

interface IProps extends IBaseProps {
  onClose: () => void;
  open: boolean;
  onFilter: () => void;
}

function BaseFilterDrawler(props: IProps) {
  return (
    <Drawer
      title={lang.t('default.filter')}
      onClose={props.onClose}
      extra={
        <Tooltip title={lang.t('default.apply')} placement="bottom">
          <Button size="large" icon={<CheckOutlined />} onClick={props.onFilter} type="primary" shape="circle" />
        </Tooltip>
      }
      open={props.open}
    >
      {props.children}
    </Drawer>
  );
}
export default memo(BaseFilterDrawler);
