import { IBaseResponse, IParams, ITotalRes } from 'src/types';
import { http } from 'src/utils';

export default class BaseService<T, C = {}, U = {}> {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  public async list(query: IParams<T>) {
    const { data } = await http.get<IBaseResponse<T[]>>(this.url, { params: query });
    return data.data;
  }

  public async export(query: IParams<T>) {
    const { data } = await http.get<IBaseResponse<T[]>>(this.url, { params: query });
    return data;
  }

  public async detail(id: string, query?: IParams<T>) {
    const { data } = await http.get<IBaseResponse<T>>(`${this.url}/${id}`, { params: query });
    return data.data;
  }

  public async getTotal(query: IParams<T>, key: string = 'id') {
    const { data } = await http.get<IBaseResponse<ITotalRes[]>>(this.url, {
      params: { ...query, aggregate: { countDistinct: key } },
    });

    if (data.data && data.data.length > 0) {
      // @ts-ignore
      return Number(data.data[0].countDistinct[key]);
    }

    return 0;
  }

  public async create(obj: C) {
    const { data } = await http.post<IBaseResponse<T>>(this.url, obj);
    return data.data;
  }

  public async createMulti(obj: C[]) {
    const { data } = await http.post<IBaseResponse<T>>(this.url, obj);
    return data.data;
  }

  public async update(id: string | number, obj: U) {
    const { data } = await http.patch<T>(`${this.url}/${id}`, obj);
    return data;
  }

  public async updateMulti(ids: string[], obj: U) {
    const { data } = await http.patch<T>(`${this.url}`, {
      keys: ids,
      data: obj,
    });
    return data;
  }

  public async delete(id: string | number) {
    const { data } = await http.delete<T>(`${this.url}/${id}`);
    return data;
  }

  public async deleteMulti(ids: string[]) {
    const { data } = await http.delete<T>(this.url, { data: ids });
    return data;
  }
}
