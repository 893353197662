import lang from 'src/lang/lang';
import {
  EAttendanceType,
  EExportParam,
  EProductAchievementType,
  EPropertyCategorySelectType,
  EPropertyCategoryType,
  EScheduleAchievementType,
  EScheduleConfigEndType,
  EScheduleConfigMode,
  EScheduleConfigRepeatType,
  EScheduleMode,
  ETaskRole,
  IBaseList,
  IShiftType,
  LayoutColor,
  LayoutType,
} from 'src/types';
import { IWageTimeType } from 'src/types/wage_time.type';

export default class OPTIONS {
  static genderOpts: IBaseList<number>[] = [
    { label: lang.t('utils.male'), value: 1 },
    { label: lang.t('utils.female'), value: 2 },
    { label: lang.t('utils.other'), value: 3 },
  ];

  static layoutOpts: IBaseList<LayoutType>[] = [
    { label: lang.t('utils.vertical'), value: LayoutType.VERTICAL },
    { label: lang.t('utils.horizontal'), value: LayoutType.HORIZONTAL },
  ];

  static TypeNewsOpts: IBaseList<String>[] = [
    { label: lang.t('utils.news'), value: 'NEW' },
    { label: lang.t('utils.notification'), value: 'NOTIFICATION' },
  ];

  static PaymentFilterTypeOpts: IBaseList<String>[] = [
    { label: lang.t('utils.checkout'), value: 'CHECKOUT' },
    { label: lang.t('utils.return'), value: 'RETURN' },
    { label: lang.t('utils.refund'), value: 'TURNBACK' },
    { label: lang.t('utils.session'), value: 'SESSION' },
  ];

  static EmotionTypeOpts: IBaseList<String>[] = [
    { label: lang.t('utils.like'), value: 'LIKE' },
    { label: lang.t('utils.love'), value: 'LOVE' },
    { label: lang.t('utils.haha'), value: 'LAUGH' },
    { label: lang.t('utils.wow'), value: 'WOW' },
    { label: lang.t('utils.sad'), value: 'SAD' },
    { label: lang.t('utils.angry'), value: 'ANGRY' },
  ];

  static TypeWageSellOpts: IBaseList<String>[] = [
    { label: lang.t('utils.product'), value: 'PRODUCT' },
    { label: lang.t('utils.income'), value: 'INCOME' },
  ];

  static StatusWalletOpts: IBaseList<boolean>[] = [
    { label: lang.t('utils.default_wallet'), value: true },
    { label: lang.t('utils.not_active'), value: false },
  ];

  static layoutColorOpts: IBaseList<LayoutColor>[] = [
    { label: lang.t('utils.light'), value: LayoutColor.LIGHT },
    { label: lang.t('utils.dark'), value: LayoutColor.DARK },
  ];

  static typeExportOpts: IBaseList<EExportParam>[] = [
    { label: lang.t('utils.CSV'), value: EExportParam.csv },
    { label: lang.t('utils.JSON'), value: EExportParam.json },
    { label: lang.t('utils.XML'), value: EExportParam.xml },
    { label: lang.t('utils.YAML'), value: EExportParam.yaml },
  ];

  static typePropertyCategoryOpts: IBaseList<EPropertyCategoryType>[] = [
    { label: lang.t('utils.color'), value: EPropertyCategoryType.COLOR },
    { label: lang.t('utils.location'), value: EPropertyCategoryType.LOCATION },
    { label: lang.t('utils.number'), value: EPropertyCategoryType.NUMBER },
    { label: lang.t('utils.string'), value: EPropertyCategoryType.STRING },
    { label: lang.t('utils.text'), value: EPropertyCategoryType.TEXT },
    { label: lang.t('utils.time'), value: EPropertyCategoryType.TIME },
  ];

  static selectTypePropertyCategoryOpts: IBaseList<EPropertyCategorySelectType>[] = [
    { label: lang.t('utils.boolean'), value: EPropertyCategorySelectType.BOOL },
    { label: lang.t('utils.dropdown_selection'), value: EPropertyCategorySelectType.COMBO },
    { label: lang.t('utils.free_input'), value: EPropertyCategorySelectType.FREE },
  ];

  static modeScheduleOpts: IBaseList<EScheduleMode>[] = [
    { label: lang.t('utils.offline'), value: EScheduleMode.OFFLINE },
    { label: lang.t('utils.online'), value: EScheduleMode.ONLINE },
  ];

  static configModeScheduleOpts: IBaseList<EScheduleConfigMode>[] = [
    { label: lang.t('utils.instant'), value: EScheduleConfigMode.INSTANT },
    { label: lang.t('utils.once'), value: EScheduleConfigMode.ONCE },
    { label: lang.t('utils.repeat'), value: EScheduleConfigMode.REPEAT },
  ];

  static configRepeatTypeScheduleOpts: IBaseList<EScheduleConfigRepeatType>[] = [
    { label: lang.t('utils.day'), value: EScheduleConfigRepeatType.BY_DAY },
    { label: lang.t('utils.week'), value: EScheduleConfigRepeatType.BY_WEEK },
    { label: lang.t('utils.month'), value: EScheduleConfigRepeatType.BY_MONTH },
    { label: lang.t('utils.month_by_week'), value: EScheduleConfigRepeatType.BY_MONTH_WEEK },
    { label: lang.t('utils.year'), value: EScheduleConfigRepeatType.BY_YEAR },
    { label: lang.t('utils.year_by_week'), value: EScheduleConfigRepeatType.BY_YEAR_WEEK },
    { label: lang.t('utils.year_by_month_by_week'), value: EScheduleConfigRepeatType.BY_YEAR_MONTH_WEEK },
    { label: lang.t('utils.custom'), value: EScheduleConfigRepeatType.CUSTOM },
  ];

  static configEndTypeScheduleOpts: IBaseList<EScheduleConfigEndType>[] = [
    { label: lang.t('utils.end_date'), value: EScheduleConfigEndType.DATE },
    { label: lang.t('utils.count'), value: EScheduleConfigEndType.COUNT },
  ];

  static configModeTaskOpts: IBaseList<EScheduleConfigMode>[] = [
    // { label: 'Tức thì', value: EScheduleConfigMode.INSTANT },
    { label: lang.t('utils.once'), value: EScheduleConfigMode.ONCE },
    { label: lang.t('utils.repeat'), value: EScheduleConfigMode.REPEAT },
  ];

  static taskRoleOpts: IBaseList<ETaskRole>[] = [
    { label: lang.t('utils.group_deputy'), value: ETaskRole.DEPUTY },
    { label: lang.t('utils.member'), value: ETaskRole.MEMBER },
    { label: lang.t('utils.group_leader'), value: ETaskRole.MONITOR },
  ];

  static attendanceTypeOpts: IBaseList<EAttendanceType>[] = [
    { label: lang.t('utils.in'), value: EAttendanceType.IN },
    { label: lang.t('utils.out'), value: EAttendanceType.OUT },
  ];

  static currencyOpts: IBaseList<string>[] = [
    { label: lang.t('utils.VND'), value: 'VND' },
    { label: lang.t('utils.USD'), value: 'USD' },
    { label: lang.t('utils.EURO'), value: 'EURO' },
  ];

  static typeOpts: IBaseList<string>[] = [
    { label: lang.t('utils.individual'), value: 'Individual' },
    { label: lang.t('utils.tenant'), value: 'Tenant' },
    { label: lang.t('utils.department'), value: 'Department' },
    { label: lang.t('utils.user'), value: 'User' },
  ];

  static typeDebtOpts: IBaseList<string>[] = [
    { label: lang.t('utils.tenant'), value: 'Tenant' },
    { label: lang.t('utils.user'), value: 'User' },
  ];

  static dayInWeekOpts: IBaseList<string>[] = [
    { label: lang.t('utils.monday'), value: '1' },
    { label: lang.t('utils.tuesday'), value: '2' },
    { label: lang.t('utils.wednesday'), value: '3' },
    { label: lang.t('utils.thursday'), value: '4' },
    { label: lang.t('utils.friday'), value: '5' },
    { label: lang.t('utils.saturday'), value: '6' },
    { label: lang.t('utils.sunday'), value: '0' },
  ];

  static typeSelectOpts: IBaseList<string>[] = [
    { label: lang.t('utils.day_in_week'), value: 'DayInWeek' },
    { label: lang.t('utils.day_in_month'), value: 'DayInMonth' },
  ];

  static ERoleOpts: IBaseList<string>[] = [
    { label: lang.t('utils.level_department'), value: '2' },
    { label: lang.t('utils.level_tenant'), value: '1' },
  ];

  static MonneyOpts: IBaseList<string>[] = [
    { label: lang.t('utils.cash_outflow'), value: '1' },
    { label: lang.t('utils.cash_inflow'), value: '2' },
  ];

  static VoucherTypeOpts: IBaseList<string>[] = [
    { label: lang.t('utils.percentage'), value: 'PERCENT' },
    { label: lang.t('utils.currency'), value: 'CURRENCY' },
    { label: lang.t('utils.gift'), value: 'GIFT' },
  ];

  static selectAchievementOpts: IBaseList<string>[] = [
    { label: lang.t('utils.product'), value: 'PRODUCT' },
    { label: lang.t('utils.schedule'), value: 'SCHEDULE' },
    { label: lang.t('utils.task'), value: 'TASK' },
  ];

  static selectProductTypeOpts: IBaseList<string>[] = [
    { label: lang.t('utils.quantity'), value: EProductAchievementType.QUANTITY },
    { label: lang.t('utils.value'), value: EProductAchievementType.VALUE },
  ];

  static selectScheduleTypeOpts: IBaseList<string>[] = [
    { label: lang.t('utils.attended'), value: EScheduleAchievementType.ATTENDED },
    { label: lang.t('utils.on_time'), value: EScheduleAchievementType.ONTIME },
  ];

  static TypeWageTimeOpts: IBaseList<IWageTimeType>[] = [
    { label: lang.t('utils.minute'), value: IWageTimeType.MINUTE },
    { label: lang.t('utils.hour'), value: IWageTimeType.HOUR },
    { label: lang.t('utils.day'), value: IWageTimeType.DAY },
    { label: lang.t('utils.week'), value: IWageTimeType.WEEK },
    { label: lang.t('utils.month'), value: IWageTimeType.MONTH },
  ];

  static TypeShiftOpts: IBaseList<IShiftType>[] = [
    { label: lang.t('utils.day'), value: IShiftType.DAY },
    { label: lang.t('utils.week'), value: IShiftType.WEEK },
    { label: lang.t('utils.month'), value: IShiftType.MONTH },
    { label: lang.t('utils.year'), value: IShiftType.YEAR },
    { label: lang.t('utils.month_by_week'), value: IShiftType.MONTH_BY_WEEK },
    { label: lang.t('utils.year_by_week'), value: IShiftType.YEAR_BY_WEEK },
    { label: lang.t('utils.year_by_month_by_week'), value: IShiftType.YEAR_BY_MONTH_BY_WEEK },
  ];

  static deliveryStatusOpts: IBaseList<string>[] = [
    { label: lang.t('utils.cancel_order'), value: '-1' },
    { label: lang.t('utils.not_yet_received'), value: '1' },
    { label: lang.t('utils.received'), value: '2' },
    { label: lang.t('utils.item_picked_up_received_in_stock'), value: '3' },
    { label: lang.t('utils.item_picked_up_received_in_stock'), value: '4' },
    { label: lang.t('utils.delivery_not_reconciled'), value: '5' },
    { label: lang.t('utils.reconciled'), value: '6' },
    { label: lang.t('utils.unable_to_retrieve_goods'), value: '7' },
    { label: lang.t('utils.delay_in_pickup'), value: '8' },
    { label: lang.t('utils.unalbe_to_deliver'), value: '9' },
    { label: lang.t('utils.delay_in_delivery'), value: '10' },
    { label: lang.t('utils.completed_debt_reconciliation_for_returned'), value: '11' },
    { label: lang.t('utils.pickup_coordinated_in_progress'), value: '12' },
    { label: lang.t('utils.refunded_order'), value: '13' },
    { label: lang.t('utils.returning'), value: '20' },
    { label: lang.t('utils.return_completed'), value: '21' },
    { label: lang.t('utils.shipper_reported_goods_collected'), value: '123' },
    { label: lang.t('utils.shipper_reported_unable_to_collect'), value: '127' },
    { label: lang.t('utils.shipper_reported_a_delay_in_collecting'), value: '128' },
    { label: lang.t('utils.shipper_reported_delivered'), value: '45' },
    { label: lang.t('utils.shipper_reported_unable_to_deliver'), value: '49' },
    { label: lang.t('utils.shipper_reported_a_delay_in_delivery'), value: '410' },
  ];
}
