import { IBaseResponse } from 'src/types';
import { http } from 'src/utils';

export default class VietQrService {
  url = 'https://api.vietqr.io/v2';

  public async listBank() {
    const { data } = await http.get<IBaseResponse<any>>(`${this.url}/banks`);
    return data.data;
  }
}
