import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Flex, Layout, Menu } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { memo, Suspense, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SpinnerLoader } from 'src/libraries';
import { LayoutColor } from 'src/types';
import { FUNCS } from 'src/utils';
import Chat from './components/Chat';
import Notification from './components/Notification';

const { Header, Content, Sider } = Layout;

interface IProps {
  logo: string;
  menus: ItemType<MenuItemType>[];
  accountItems: ItemType[];
  accountAvatar: string;
  theme: LayoutColor;
  pathname: string;
  logoImage: string;
}

function VerticalLayout(props: IProps) {
  const [collapsed, setCollapsed] = useState(false);
  const { innerHeight: height } = window;

  const rootPath = useMemo<string>(() => {
    return `/${props.pathname.split('/')[1]}`;
  }, [props.pathname]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: props.theme === LayoutColor.DARK ? '#002140' : 'white',
          borderBottom: '1px solid rgba(5, 5, 5, 0.06)',
        }}
      >
        <div
          style={{
            display: 'flex',
            margin: '16px 6px',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <img src={props.logoImage} alt="" width="40" />
          <h3 style={{ color: props.theme === LayoutColor.DARK ? 'white' : 'black', marginLeft: 8 }}>{props.logo}</h3>
        </div>

        <Flex>
          <Notification />

          <Chat />

          <div className="icon-wrapper">
            <Dropdown menu={{ items: props.accountItems }} placement="bottomLeft">
              {props.accountAvatar ? (
                <Avatar size="large" src={<img src={FUNCS.getFullMedialUrl(props.accountAvatar)} alt="avatar" />} />
              ) : (
                <Avatar size="large" style={{ backgroundColor: 'var(--colorNormal)' }} icon={<UserOutlined />} />
              )}
            </Dropdown>
          </div>
        </Flex>
      </Header>

      <Layout>
        <Sider
          theme={props.theme}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{ height: height - 104, overflow: 'scroll' }}
        >
          <Menu
            theme={props.theme}
            mode="inline"
            // defaultOpenKeys={[pathBack]}
            selectedKeys={[rootPath]}
            items={props.menus}
          />
        </Sider>
        <Content
          style={{
            position: 'relative',
            maxHeight: height - 64,
            overflowY: 'scroll',
          }}
        >
          <Suspense fallback={<SpinnerLoader bg={false} />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}

export default memo(VerticalLayout);
