import dayjs from 'dayjs';
import { createContext, memo, useContext, useMemo, useState } from 'react';
import {
  EScheduleConfigEndType,
  EScheduleConfigMode,
  EScheduleConfigRepeatType,
  IBaseProps,
  IScheduleConfigProvider,
} from 'src/types';

interface IConfigContext {
  config: IScheduleConfigProvider;
  setConfig: React.Dispatch<React.SetStateAction<IScheduleConfigProvider>>;
}

const initConfig: IScheduleConfigProvider = {
  mode: EScheduleConfigMode.INSTANT,
  duration: 0,
  start: dayjs(),
  repeat_type: EScheduleConfigRepeatType.BY_DAY,
  repeat_end: undefined,
  repeat_count: undefined,
  repeat_duration: 1,
  repeat_config_time: [],
  schedule_default: [],
  end_type: EScheduleConfigEndType.DATE,
};

const ConfigContext = createContext<IConfigContext>({
  config: initConfig,
  setConfig: () => undefined,
});

export const useScheduleConfig = () => useContext(ConfigContext);

function ScheduleConfigProvider(props: IBaseProps) {
  const [state, setState] = useState<IScheduleConfigProvider>(initConfig);

  const config = useMemo<IConfigContext>(() => {
    return {
      config: state,
      setConfig: setState,
    };
  }, [state]);

  return <ConfigContext.Provider value={config}>{props.children}</ConfigContext.Provider>;
}

export default memo(ScheduleConfigProvider);
