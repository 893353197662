import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/stores';
import { setRefresh } from './refresh.slice';

export default function useRefresh() {
  const dispatch = useDispatch();
  const refresh = useAppSelector((state) => state.refresh);

  const changeRefresh = (refresh: boolean) => {
    dispatch(setRefresh(refresh));
  };

  return {
    refresh,
    changeRefresh,
  };
}
