import { Spin } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { UserService } from 'src/services';
import { useTenant, useUser } from 'src/stores';
import { IParams, IUser } from 'src/types';
import { FUNCS } from 'src/utils';

interface IProps {
  keysearch?: string;
  handleOpenChat: (user: IUser) => void;
}

interface IState {
  loading: boolean;
  data: IUser[];
}

const initState: IState = {
  loading: false,
  data: [],
};

const svUser = new UserService();

function ListUser(props: IProps) {
  const [state, setState] = useState<IState>(initState);
  const { tenant } = useTenant();
  const { user } = useUser();

  useEffect(() => {
    debounceFn(props.keysearch);
  }, [props.keysearch]);

  const fetchData = async (search?: string) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const query: IParams<IUser> = {
        limit: 15,
        fields: ['id', 'first_name', 'last_name', 'avatar'],
        filter: {
          tenant_id: { _eq: tenant?.id },
          id: { _neq: user?.id },
        },
        search,
      };
      const res = await svUser.list(query);
      setState((prev) => ({ ...prev, data: res }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const debounceFn = _.debounce(fetchData, 800);

  return (
    <div className="list-chat">
      <Spin spinning={state.loading}>
        {state.data.map((item) => (
          <div className="chat-item" key={item.id} onClick={() => props.handleOpenChat(item)}>
            <div className="profile-pic">
              <img src={FUNCS.getFullMedialUrl(item?.avatar)} alt="avatar" />
            </div>
            <div className="chat-details">
              <div className="chat-name">{FUNCS.getFullName(item)}</div>
            </div>
          </div>
        ))}
      </Spin>
    </div>
  );
}

export default ListUser;
