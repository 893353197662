import { Alert, Breadcrumb, Row, Space, Statistic } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import dayjs from 'dayjs';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import ModalAddType from 'src/components/ModalAddType';
import lang from 'src/lang/lang';
import { Routes } from 'src/routes';
import { useTenant } from 'src/stores';
import { IBaseProps } from 'src/types';
import { SpinnerLoader } from './spinner';

interface IProps extends IBaseProps {
  renderRight?: JSX.Element | React.ReactNode;
  title?: string | JSX.Element | React.ReactNode;
  // formSearch?: JSX.Element | React.ReactNode;
  // paginate?: JSX.Element | React.ReactNode;
  breadcrumbs?: ItemType[];
  renderRightBreadcrumb?: JSX.Element | React.ReactNode;
  loading?: boolean;
}

function Container(props: IProps) {
  const { innerHeight: height } = window;

  const { tenant } = useTenant();

  const license = tenant?.licenses?.[0];

  return (
    <div style={{ position: 'relative', minHeight: height - 96, margin: 16, paddingBottom: 12 }}>
      {license && dayjs(license.end).diff(dayjs(), 'days') < 7 && (
        <Alert
          message={
            <Space>
              <span>{lang.t('library.license_expired', { name: license.license_package_id.name })}</span>
              <Statistic.Countdown
                valueStyle={{ fontSize: 14 }}
                value={dayjs(license.end).valueOf()}
                format={lang.t('library.remain_format')}
              />
              <Link to={Routes.LICENSE.path}>{lang.t('library.renew_now')}</Link>
            </Space>
          }
          type="warning"
          style={{ marginBottom: 12 }}
          closable
        />
      )}

      {props.loading && <SpinnerLoader bg />}
      <Row align="middle" justify="space-between">
        {props.breadcrumbs && <Breadcrumb items={props.breadcrumbs} style={{ marginBottom: 8 }} />}
        {props.renderRightBreadcrumb}
      </Row>
      <Row justify="space-between" style={{ marginBottom: 4 }} align="middle">
        <h3>{props.title}</h3>
        <div>{props.renderRight}</div>
      </Row>
      {props.children}
      <ModalAddType />
    </div>
  );
}

export default memo(Container);
