import { Radio } from 'antd';
import React from 'react';
import { BaseFormItem } from './BaseFormItem';
import { IBaseFormItemProps } from './form.type';

interface IProps extends React.ComponentProps<typeof Radio.Group>, IBaseFormItemProps {}

export function BaseRadio(props: IProps) {
  return (
    <BaseFormItem {...props}>
      <Radio.Group
        {...props}
        style={{ maxHeight: 400, overflowY: 'scroll', display: 'block' }}
        className="checkbox-list"
      />
    </BaseFormItem>
  );
}
