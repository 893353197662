import { lazy, memo, Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import ScheduleConfigProvider from 'src/components/schedule-config/ScheduleConfigProvider';
import LayoutClient from 'src/layouts';
import { SpinnerLoader } from 'src/libraries';
import { Routes as Router } from 'src/routes';
import DepartmentScreen from 'src/screens/department';
import CreateDepartmentScreen from 'src/screens/department/create';
import DetailDepartmentScreen from 'src/screens/department/detail';
import RegisterTenantScreen from 'src/screens/tenant/register';
import { useLang, useTenant, useUser } from 'src/stores';
import { EService, IService, ISetting } from 'src/types';
import { FUNCS } from 'src/utils';
import InitAppProvider from './initApp.provider';
import SocketProvider from './socket.provider';

const LoginScreen = lazy(() => import('src/screens/auth/login/index'));
const RegisterScreen = lazy(() => import('src/screens/auth/register'));
const VerificationUserScreen = lazy(() => import('src/screens/auth/register/verification'));
const ResetPasswordScreen = lazy(() => import('src/screens/auth/reset-password'));
const Page404Screen = lazy(() => import('src/screens/errors/page404'));
const Page500Screen = lazy(() => import('src/screens/errors/page500'));
const Privacy = lazy(() => import('src/screens/privacy/privacy'));
const ProfileScreen = lazy(() => import('src/screens/profile'));
const DownloadScreen = lazy(() => import('src/screens/download'));

const DashboardScreen = lazy(() => import('src/screens/dashboard'));

const ScheduleScreen = lazy(() => import('src/screens/scheduled'));

const ShelfScreen = lazy(() => import('src/screens/facility/shelf'));

const TableScreen = lazy(() => import('src/screens/facility/table'));

const StorehouseScreen = lazy(() => import('src/screens/facility/storehouse'));
const StorehouseDetailScreen = lazy(() => import('src/screens/facility/storehouse/detail'));

const DocumentScreen = lazy(() => import('src/screens/document'));
const SubjectScreen = lazy(() => import('src/screens/subject'));
const CreateScheduleScreen = lazy(() => import('src/screens/scheduled/create'));
// news
const NewScreen = lazy(() => import('src/screens/news'));
const CreateNewScreen = lazy(() => import('src/screens/news/create'));
const DetailNewScreen = lazy(() => import('src/screens/news/detail'));
// waller
const WalletScreen = lazy(() => import('src/screens/wallet'));
const CreateWalletScreen = lazy(() => import('src/screens/wallet/create'));
const DetailWalletScreen = lazy(() => import('src/screens/wallet/detail'));
// payment
const PaymentScreen = lazy(() => import('src/screens/payment'));
const CreatePaymentScreen = lazy(() => import('src/screens/payment/create'));
const DetailPaymentScreen = lazy(() => import('src/screens/payment/detail'));
// product
const ProductScreen = lazy(() => import('src/screens/product'));
const CreateProductScreen = lazy(() => import('src/screens/product/create'));
const EditProductScreen = lazy(() => import('src/screens/product/edit'));

const TaskScreen = lazy(() => import('src/screens/task'));
const CreateTaskScreen = lazy(() => import('src/screens/task/create'));
const DetailTaskScreen = lazy(() => import('src/screens/task/detail'));

const AttendanceScreen = lazy(() => import('src/screens/attendance'));

const WageTimeScreen = lazy(() => import('src/screens/wage/wage_time'));
const CreateWageTimeScreen = lazy(() => import('src/screens/wage/wage_time/create'));
const EditWageTimeScreen = lazy(() => import('src/screens/wage/wage_time/edit'));

const WageScheduleScreen = lazy(() => import('src/screens/wage/wage_schedule'));
const CreateWageScheduleScreen = lazy(() => import('src/screens/wage/wage_schedule/create'));
const EditWageScheduleScreen = lazy(() => import('src/screens/wage/wage_schedule/edit'));

const WageTaskScreen = lazy(() => import('src/screens/wage/wage_task'));
const CreateWageTaskScreen = lazy(() => import('src/screens/wage/wage_task/create'));
const EditWageTaskScreen = lazy(() => import('src/screens/wage/wage_task/edit'));

const WageSellScreen = lazy(() => import('src/screens/wage/wage_sell'));
const CreateWageSellScreen = lazy(() => import('src/screens/wage/wage_sell/create'));
const EditWageSellScreen = lazy(() => import('src/screens/wage/wage_sell/edit'));

// const WageAchievementScreen = lazy(() => import('src/screens/wage/wage_achievement'));
// const CreateWageAchievementScreen = lazy(() => import('src/screens/wage/wage_achievement/create'));
// const EditWageAchievementScreen = lazy(() => import('src/screens/wage/wage_achievement/edit'));

const WageScreen = lazy(() => import('src/screens/wage'));

const SessionScreen = lazy(() => import('src/screens/session'));
const CreateSessionScreen = lazy(() => import('src/screens/session/create'));
const DetailSessionScreen = lazy(() => import('src/screens/session/detail'));

const ReturnScreen = lazy(() => import('src/screens/return'));
const CreateReturnScreen = lazy(() => import('src/screens/return/create'));
const DetailReturnScreen = lazy(() => import('src/screens/return/detail'));

// const SaleScreen = lazy(() => import('src/screens/sale'));
// const DetailSaleScreen = lazy(() => import('src/screens/sale/detail'));

const CheckoutScreen = lazy(() => import('src/screens/checkout'));
const CreateCheckoutScreen = lazy(() => import('src/screens/checkout/create'));
const CreateFastCheckoutScreen = lazy(() => import('src/screens/checkout/create-fast'));
const DetailCheckoutScreen = lazy(() => import('src/screens/checkout/detail'));

const TurnbackScreen = lazy(() => import('src/screens/turnback'));
const CreateTurnbackScreen = lazy(() => import('src/screens/turnback/create'));
const DetailTurnbackScreen = lazy(() => import('src/screens/turnback/detail'));

const GeneralSettingScreen = lazy(() => import('src/screens/setting/general-setting'));
const SocialSettingScreen = lazy(() => import('src/screens/setting/social'));
const LicenseSettingScreen = lazy(() => import('src/screens/setting/license'));
const PaymentSettingScreen = lazy(() => import('src/screens/setting/payment'));
const SepayGuideScreen = lazy(() => import('src/screens/setting/payment/guide_sepay'));

const ReportUnsoldScreen = lazy(() => import('src/screens/report/unsold'));
const ReportInventoryBookScreen = lazy(() => import('src/screens/report/inventory-book'));
const ReportRevenueScreen = lazy(() => import('src/screens/report/revenue'));

const RoleScreen = lazy(() => import('src/screens/role'));
const CreateRoleScreen = lazy(() => import('src/screens/role/create'));
const EditRoleScreen = lazy(() => import('src/screens/role/edit'));

const DebtScreen = lazy(() => import('src/screens/debt'));
const CreateDebtScreen = lazy(() => import('src/screens/debt/create'));
const EditDebtScreen = lazy(() => import('src/screens/debt/edit'));

// const CreateRoleScreen = lazy(() => import('src/screens/role/create'));
// const EditRoleScreen = lazy(() => import('src/screens/role/edit'));

const ProductCategoryScreen = lazy(() => import('src/screens/product_category'));
const CreateProductCategoryScreen = lazy(() => import('src/screens/product_category/create'));
const EditProductCategoryScreen = lazy(() => import('src/screens/product_category/edit'));

const PropertyCategoryScreen = lazy(() => import('src/screens/property_category'));

const RankScreen = lazy(() => import('src/screens/rank'));
const CreateRankScreen = lazy(() => import('src/screens/rank/create'));
const EditRankScreen = lazy(() => import('src/screens/rank/edit'));

// const AchievementScreen = lazy(() => import('src/screens/achievement'));
// const CreateAchievementScreen = lazy(() => import('src/screens/achievement/create'));
// const EditAchievementScreen = lazy(() => import('src/screens/achievement/edit'));

const StaffScreen = lazy(() => import('src/screens/user/staff'));
const CreateStaffScreen = lazy(() => import('src/screens/user/staff/create'));
const DetailStaffScreen = lazy(() => import('src/screens/user/staff/detail'));

const CustomerScreen = lazy(() => import('src/screens/user/customer'));
const DetailCustomerScreen = lazy(() => import('src/screens/user/customer/detail'));

const ShiftScreen = lazy(() => import('src/screens/shift'));
const CreateShiftScreen = lazy(() => import('src/screens/shift/create'));
const EditShiftScreen = lazy(() => import('src/screens/shift/edit'));

const DiscountScreen = lazy(() => import('src/screens/discount'));
const CreateDiscountScreen = lazy(() => import('src/screens/discount/create'));
const EditDiscountScreen = lazy(() => import('src/screens/discount/edit'));

const VoucherScreen = lazy(() => import('src/screens/voucher'));
const CreateVoucherScreen = lazy(() => import('src/screens/voucher/create'));
const EditVoucherScreen = lazy(() => import('src/screens/voucher/edit'));

function RouteProvider() {
  const { tenant } = useTenant();
  const { isAdmin } = useUser();
  const { locale } = useLang();
  console.log(locale);

  const renderServiceRoute = (setting: ISetting, service: IService, path: string) => {
    switch (service.name) {
      case EService.schedule:
        return (
          <>
            <Route path={path} element={<ScheduleScreen info={setting} />} />
            <Route
              path={`${path}/them-moi`}
              element={
                <ScheduleConfigProvider>
                  <CreateScheduleScreen info={setting} />
                </ScheduleConfigProvider>
              }
            />
          </>
        );
      case EService.subject:
        return (
          <>
            <Route path={path} element={<SubjectScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<SubjectScreen info={setting} />} />
          </>
        );

      case EService.news:
        return (
          <>
            <Route path={path} element={<NewScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateNewScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<DetailNewScreen info={setting} />} />
          </>
        );
      case EService.wallet:
        return (
          <>
            <Route path={path} element={<WalletScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateWalletScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<DetailWalletScreen info={setting} />} />
          </>
        );

      case EService.payment:
        return (
          <>
            <Route path={path} element={<PaymentScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreatePaymentScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<DetailPaymentScreen info={setting} />} />
          </>
        );
      case EService.product:
        return (
          <>
            <Route path={path} element={<ProductScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateProductScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditProductScreen info={setting} />} />
          </>
        );
      case EService.task:
        return (
          <>
            <Route path={path} element={<TaskScreen info={setting} />} />
            <Route
              path={`${path}/them-moi`}
              element={
                <ScheduleConfigProvider>
                  <CreateTaskScreen info={setting} />
                </ScheduleConfigProvider>
              }
            />
            <Route path={`${path}/:id`} element={<DetailTaskScreen info={setting} />} />
          </>
        );
      case EService.attendance:
        return <Route path={path} element={<AttendanceScreen info={setting} />} />;
      case EService.wage_schedule:
        return (
          <>
            <Route path={path} element={<WageScheduleScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateWageScheduleScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditWageScheduleScreen info={setting} />} />
          </>
        );
      case EService.wage_time:
        return (
          <>
            <Route path={path} element={<WageTimeScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateWageTimeScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditWageTimeScreen info={setting} />} />
          </>
        );
      case EService.wage_task:
        return (
          <>
            <Route path={path} element={<WageTaskScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateWageTaskScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditWageTaskScreen info={setting} />} />
          </>
        );
      case EService.wage_sell:
        return (
          <>
            <Route path={path} element={<WageSellScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateWageSellScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditWageSellScreen info={setting} />} />
          </>
        );
      // case EService.wage_achievement:
      //   return (
      //     <>
      //       <Route path={path} element={<WageAchievementScreen info={setting} />} />
      //       <Route path={`${path}/them-moi`} element={<CreateWageAchievementScreen info={setting} />} />
      //       <Route path={`${path}/:id`} element={<EditWageAchievementScreen info={setting} />} />
      //     </>
      //   );
      case EService.wage:
        return <Route path={path} element={<WageScreen info={setting} />} />;

      case EService.room:
        return (
          <>
            <Route path={path} element={<ShelfScreen info={setting} />} />
            {/* <Route path={`${path}/:id`} element={<ShelfDetailScreen info={setting} />} />  */}
          </>
        );

      case EService.table:
        return (
          <>
            <Route path={path} element={<TableScreen info={setting} />} />
            {/* <Route path={`${path}/:id`} element={<ShelfDetailScreen info={setting} />} />  */}
          </>
        );

      case EService.storehouse:
        return (
          <>
            <Route path={path} element={<StorehouseScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<StorehouseDetailScreen info={setting} />} />
          </>
        );

      case EService.shift:
        return (
          <>
            <Route path={path} element={<ShiftScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateShiftScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditShiftScreen info={setting} />} />
          </>
        );
      case EService.document:
        return <Route path={path} element={<DocumentScreen info={setting} />} />;

      case EService.session:
        return (
          <>
            <Route path={path} element={<SessionScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateSessionScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<DetailSessionScreen info={setting} />} />
          </>
        );

      case EService.return:
        return (
          <>
            <Route path={path} element={<ReturnScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateReturnScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<DetailReturnScreen info={setting} />} />
          </>
        );

      // case EService.sale:
      //   return (
      //     <>
      //       <Route path={path} element={<SaleScreen info={setting} />} />
      //       <Route path={`${path}/:id`} element={<DetailSaleScreen info={setting} />} />
      //     </>
      //   );

      case EService.checkout:
        return (
          <>
            <Route path={path} element={<CheckoutScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateCheckoutScreen info={setting} />} />
            <Route path={`${path}/them-moi-nhanh`} element={<CreateFastCheckoutScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<DetailCheckoutScreen info={setting} />} />
          </>
        );
      case EService.turnback:
        return (
          <>
            <Route path={path} element={<TurnbackScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateTurnbackScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<DetailTurnbackScreen info={setting} />} />
          </>
        );

      case EService.product_category:
        return (
          <>
            <Route path={path} element={<ProductCategoryScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateProductCategoryScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditProductCategoryScreen info={setting} />} />
          </>
        );

      case EService.property_category:
        return (
          <>
            <Route path={path} element={<PropertyCategoryScreen info={setting} />} />
            {/* <Route path={`${path}/them-moi`} element={<CreatePropertyCategoryScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditPropertyCategoryScreen info={setting} />} /> */}
          </>
        );

      case EService.report_unsold:
        return <Route path={path} element={<ReportUnsoldScreen info={setting} />} />;

      case EService.report_inventory_book:
        return <Route path={path} element={<ReportInventoryBookScreen info={setting} />} />;

      case EService.report_revenue:
        return <Route path={path} element={<ReportRevenueScreen info={setting} />} />;

      case EService.role:
        return (
          <>
            <Route path={path} element={<RoleScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateRoleScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditRoleScreen info={setting} />} />
          </>
        );
      case EService.debt:
        return (
          <>
            <Route path={path} element={<DebtScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateDebtScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditDebtScreen info={setting} />} />
          </>
        );

      case EService.sale:
        return (
          <>
            <Route path={path} element={<DiscountScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateDiscountScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditDiscountScreen info={setting} />} />
          </>
        );

      case EService.voucher:
        return (
          <>
            <Route path={path} element={<VoucherScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateVoucherScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditVoucherScreen info={setting} />} />
          </>
        );
      case EService.rank:
        return (
          <>
            <Route path={path} element={<RankScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateRankScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<EditRankScreen info={setting} />} />
          </>
        );

      // case EService.achievement:
      //   return (
      //     <>
      //       <Route path={path} element={<AchievementScreen info={setting} />} />
      //       <Route path={`${path}/them-moi`} element={<CreateAchievementScreen info={setting} />} />
      //       <Route path={`${path}/:id`} element={<EditAchievementScreen info={setting} />} />
      //     </>
      //   );

      case EService.staff:
        return (
          <>
            <Route path={path} element={<StaffScreen info={setting} />} />
            <Route path={`${path}/them-moi`} element={<CreateStaffScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<DetailStaffScreen info={setting} />} />
          </>
        );

      case EService.customer:
        return (
          <>
            <Route path={path} element={<CustomerScreen info={setting} />} />
            <Route path={`${path}/:id`} element={<DetailCustomerScreen info={setting} />} />
          </>
        );

      default:
        break;
    }
  };

  return (
    <Routes>
      <Route
        element={
          <InitAppProvider>
            <SocketProvider>
              <RegisterTenantScreen>
                <LayoutClient />
              </RegisterTenantScreen>
            </SocketProvider>
          </InitAppProvider>
        }
      >
        <Route path={Router.HOME.path} element={<DashboardScreen />} />
        <Route path={Router.PROFILE.path} element={<ProfileScreen />} />
        {isAdmin && (
          <>
            <Route path={`${Router.GENERAL_SETTING.path}`} element={<GeneralSettingScreen />} />
            {tenant?.social && <Route path={`${Router.SOCIAL.path}`} element={<SocialSettingScreen />} />}
            <Route path={`${Router.LICENSE.path}`} element={<LicenseSettingScreen />} />
            {tenant?.language === 'vi-VN' && (
              <>
                <Route path={`${Router.PAYMENT.path}`} element={<PaymentSettingScreen />} />
                <Route path={`${Router.SEPAY_GUIDE.path}`} element={<SepayGuideScreen />} />
              </>
            )}
          </>
        )}

        {tenant?.settings.map((i) => {
          if (i.is_group_menu) return;
          const path = FUNCS.renderPath(i.label);
          if (i.service_id) {
            return renderServiceRoute(i, i.service_id, path);
          }

          return (
            <>
              <Route path={path} element={<DepartmentScreen info={i} />} />
              <Route path={`${path}/them-moi`} element={<CreateDepartmentScreen info={i} />} />
              <Route path={`${path}/:id`} element={<DetailDepartmentScreen info={i} />} />
            </>
          );
        })}
      </Route>

      <Route
        element={
          <Suspense fallback={<SpinnerLoader bg={false} />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route path={Router.LOGIN.path} element={<LoginScreen />} />
        <Route path={Router.REGISTER.path} element={<RegisterScreen />} />
        <Route path={Router.VERIFICATION.path} element={<VerificationUserScreen />} />
        <Route path={Router.RESET_PASSWORD.path} element={<ResetPasswordScreen />} />
        <Route path={Router.PRIVACY.path} element={<Privacy />} />
        <Route path={Router.PAGE500.path} element={<Page500Screen />} />
        <Route path={Router.DOWNLOAD.path} element={<DownloadScreen />} />
        <Route path="*" element={<Page404Screen />} />
      </Route>
    </Routes>
  );
}

export default memo(RouteProvider);
