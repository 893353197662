/* eslint-disable no-case-declarations */
import { Badge, Popover } from 'antd';
import { memo, useEffect, useState } from 'react';
import MessageIcon from 'src/assets/icons/MessageIcon';
import lang from 'src/lang/lang';
import { useSocket } from 'src/providers/socket.provider';
import { ECollection } from 'src/services';
import { useTenant, useUnread, useUser } from 'src/stores';
import { IMessages } from 'src/types';
import ConversationPopover from './ConversationPopover';

function Chat() {
  const [open, setOpen] = useState<boolean>(false);
  const { message, isAuth, onSubscribe, onUnSubscribe } = useSocket();
  const { user } = useUser();
  const { colorNormal } = useTenant();
  const { changeUnreadMessage, unreadMessage } = useUnread();

  useEffect(() => {
    if (open) {
      changeUnreadMessage([]);
    }
  }, [open]);

  useEffect(() => {
    onSubscribe &&
      isAuth &&
      onSubscribe(
        ECollection.message,
        'new_message',
        {
          fields: ['conversation_id'],
          filter: {
            conversation_id: {
              users: {
                directus_users_id: { _eq: user?.id },
              },
            },
            user_created: { _neq: user?.id },
          },
        },
        'create',
      );

    return () => {
      onUnSubscribe && onUnSubscribe('new_message');
    };
  }, [isAuth]);

  useEffect(() => {
    if (message?.uid !== 'new_message') return;
    switch (message?.event) {
      case 'create':
        const conversationIds = (message.data as IMessages[])?.map((i) => i.conversation_id as unknown as string) || [];
        const newConversationIds = [...unreadMessage];
        conversationIds.forEach((i) => {
          if (!newConversationIds.includes(i)) {
            newConversationIds.push(i);
          }
        });

        !open && changeUnreadMessage(newConversationIds);

        break;

      default:
        break;
    }
  }, [message]);

  return (
    <Popover
      placement="bottomRight"
      title={<b style={{ fontSize: '18px' }}>{lang.t('layout.conversation')}</b>}
      trigger="click"
      content={<ConversationPopover onClose={() => setOpen(false)} />}
      onOpenChange={(open) => {
        setOpen(open);
      }}
      arrow={false}
      // style={{ height, overflow: 'scroll' }}
      overlayClassName="custom-popover"
      open={open}
      destroyTooltipOnHide
    >
      <Badge count={unreadMessage.length} offset={[-12, 8]}>
        <div className="icon-wrapper">
          <MessageIcon color={colorNormal} size={24} />
        </div>
      </Badge>
    </Popover>
  );
}

export default memo(Chat);
