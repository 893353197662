import { IBaseEntity, IRelationBody } from './common.type';
import { IFile, IFileBody } from './file.type';
import { ITaskSchedule } from './task-schedule.type';
import { ITask } from './task.type';
import { ITaskGroup } from './task_group.type';
import { IUser } from './user.type';

export interface ITaskRole extends IBaseEntity {
  user_id: IUser;
  role: ETaskRole;
  task_id: ITask;
  task_group_id: ITaskGroup;
  files: IFile[];
  // documents: IDocument[];
  answer: string;
  passed: boolean;
  mark: number;
  user_mark: IUser;
  task_schedule_id: ITaskSchedule;
}

export interface ITaskRoleBody {
  user_id?: string;
  role?: ETaskRole;
  task_id?: string;
  task_schedule_id?: string;
  task_group_id?: string;
  files?: IRelationBody<IFileBody>;
  // documents: IDocument[];
  answer?: string;
  passed?: boolean;
  mark?: number;
  user_mark?: string;
}

export enum ETaskRole {
  MEMBER = 1,
  MONITOR = 3,
  DEPUTY = 2,
}
