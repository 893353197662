import { Button, Row, Space } from 'antd';
import { memo } from 'react';
import lang from 'src/lang/lang';

interface IProps {
  onCancel?: () => void;
  loading?: boolean;
  okBtnHtmlType?: 'submit' | 'button';
  onOk?: () => void;
}

function BaseModalAction(props: IProps) {
  return (
    <Row justify="end" style={{ marginTop: 12 }}>
      <Space>
        {!!props.onCancel && (
          <Button size="large" ghost type="primary" onClick={props.onCancel}>
            {lang.t('profile.canceler')}
          </Button>
        )}
        <Button
          size="large"
          htmlType={props.okBtnHtmlType || 'submit'}
          onClick={props.onOk}
          type="primary"
          loading={props.loading}
        >
          {lang.t('default.save')}
        </Button>
      </Space>
    </Row>
  );
}

export default memo(BaseModalAction);
