import { IBaseEntity, IRelationBody } from './common.type';
import { IDepartmentBody } from './department.type';
import { EScheduleConfigRepeatType } from './schedule.type';

export interface IShift extends IBaseEntity {
  name: string;
  type: EScheduleConfigRepeatType;
  department_id: any;
  tenant_id: string;
  itinerary: null;
  default: null;
  start: number;
  duration: number;
  day_of_week: string[];
  day: string[];
  year: any[];
  month_by_week: any[];
  year_by_week: any[];
  year_by_month_by_week: any[];
  departments: [];
  schedules: [];
  schedules_child: [];
  tasks_child: [];
  tasks: [];
}

export interface IShiftBody {
  name: string;
  type: IShiftType;
  tenant_id: string;
  start?: number;
  duration?: number;
  departments: IRelationBody<IDepartmentBody>;
  day_of_week?: string[];
  day?: string[];
  month_by_week?: any;
  year_by_week?: any;
  year_by_month_by_week?: any;
}

export enum IShiftType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  MONTH_BY_WEEK = 'MONTH_BY_WEEK',
  YEAR_BY_WEEK = 'YEAR_BY_WEEK',
  YEAR_BY_MONTH_BY_WEEK = 'YEAR_BY_MONTH_BY_WEEK',
}
