import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/stores';
import { IUser } from 'src/types';
import { setUser } from './user.slice';

export default function useUser() {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);

  const changeUser = (user: IUser | null) => {
    dispatch(setUser(user));
  };

  const isAdmin = useMemo<boolean>(() => {
    return user?.role?.policies.some((i) => i.policy.name === 'tenant editor') || false;
  }, [user]);

  return {
    user,
    changeUser,
    isAdmin,
  };
}
