import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ITenant } from 'src/types';

const tenantSlice = createSlice<ITenant | null, SliceCaseReducers<ITenant | null>>({
  name: 'tenant',
  initialState: null,
  reducers: {
    setTenant: (state, { payload }: PayloadAction<ITenant | null>) => {
      // if (state) {
      //   return {
      //     ...state,
      //     ...payload,
      //   };
      // }
      return payload;
    },
  },
});

export const { setTenant } = tenantSlice.actions;

export default tenantSlice.reducer;
