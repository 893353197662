import { CloseOutlined } from '@ant-design/icons';
import { Image, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import lang from 'src/lang/lang';
import { BaseInput, BaseUploadHidden } from 'src/libraries';
import BaseInfinityScroll from 'src/libraries/BaseInfinityScroll';
import { useSocket } from 'src/providers/socket.provider';
import { ECollection, FileService, ItemService } from 'src/services';
import { useUser } from 'src/stores';
import { IConversation } from 'src/types';
import { IMessages } from 'src/types/messages.type';
import { CONTANTS, FUNCS } from 'src/utils';
import { EventBusName } from 'src/utils/event-bus';
import EventBus from 'src/utils/event-bus/event-bus';

// interface IProps {
//   nameUser: IUser;
//   info?: any;
//   chat: IMessages[];
//   open: boolean;
//   hasMore: boolean;
//   messageTotal: number;
//   dataMessageSocket: IMessages[];
//   dataMessageCreate: IMessages[];
//   messagePage: number;
//   onClose: (id: any) => void;
//   onLoadMore: () => void;
//   hidenChatWindow: () => void;
// }

interface IState {
  loadingUpload: boolean;
  open: boolean;
  loading: boolean;
  conversation?: IConversation;
  messages: IMessages[];
  loadMore: boolean;
  input?: string;
}

const initState: IState = {
  loadingUpload: false,
  loading: false,
  messages: [],
  open: false,
  loadMore: false,
};

const svMessage = new ItemService<IMessages>(ECollection.message);
const svFile = new FileService();
const svConversation = new ItemService<IConversation>(ECollection.conversation);

function ChatWindow() {
  const [state, setState] = useState<IState>(initState);
  const { message, isAuth, onSubscribe, onUnSubscribe } = useSocket();

  const { user } = useUser();

  const subscriptions = useRef<any>();

  useEffect(() => {
    registerEventBus();

    return () => {
      unregisterEventBus();
    };
  }, []);

  useEffect(() => {
    if (state.open && state.conversation) {
      onSubscribe &&
        isAuth &&
        onSubscribe(ECollection.message, 'messages', {
          filter: {
            conversation_id: { _eq: state.conversation.id },
            user_created: { _neq: user?.id },
          },
        });
    } else {
      onUnSubscribe && onUnSubscribe('messages');
    }
  }, [state.open, state.conversation]);

  useEffect(() => {
    if (message?.uid !== 'messages') return;
    switch (message?.event) {
      case 'create':
        setState((prev) => ({ ...prev, messages: [...prev.messages, ...message.data] }));
        if (message.data.length > 0 && message.data[0] && message.data[0].user_created !== user?.id) {
          svMessage.update(message.data[0].id, { date_read: dayjs().format() });
        }
        break;
      // case 'update':
      //   const countNewMessage = message.data.length;
      //   setUnread((prev) => prev + countNewMessage);

      //   break;
      // case 'delete':
      //   const countNewMessage = message.data.length;
      //   setUnread((prev) => prev + countNewMessage);

      //   break;

      default:
        break;
    }
  }, [message]);

  useEffect(() => {
    if (state.conversation) {
      fetchMessage();
    } else {
      setState(initState);
    }
  }, [state.conversation]);

  const registerEventBus = () => {
    subscriptions.current = new Subscription();
    subscriptions.current.add(
      EventBus.getInstance().events.subscribe((data: any) => {
        switch (data.type) {
          case EventBusName.SHOW_CHAT_WINDOW:
            setState((prev) => ({ ...prev, open: true }));
            fetchDetailConversation(data.payload.id);
            break;
          default:
            break;
        }
      }),
    );
  };

  const unregisterEventBus = () => {
    subscriptions.current?.unsubscribe();
  };

  const fetchDetailConversation = async (id: string) => {
    try {
      const res = await svConversation.detail(id, {
        fields: [
          'id',
          'users.directus_users_id.first_name',
          'users.directus_users_id.last_name',
          'users.directus_users_id.id',
          'users.directus_users_id.avatar',
          'messages.id',
          'messages.user_created',
          'messages.date_read',
        ],
        deep: {
          messages: {
            _limit: 1,
            _sort: ['-date_created'],
          },
        },
      });

      if (res.messages[0] && res.messages[0].user_created !== user?.id && !res.messages[0].date_read) {
        svMessage.update(res.messages[0].id, { date_read: dayjs().format() });
      }

      setState((prev) => ({ ...prev, conversation: res }));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMessage = async (offset?: number) => {
    if (state.loading) return;
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const limit = 20;

      const res = await svMessage.list({
        limit,
        offset: offset || 0,
        fields: ['id', 'user_created', 'date_created', 'file', 'content'],
        filter: {
          conversation_id: { _eq: state.conversation?.id },
        },
        sort: ['-date_created'],
      });

      if (res.length < limit) {
        setState((prev) => ({ ...prev, messages: [...prev.messages, ...res], loadMore: false }));
      } else {
        setState((prev) => ({ ...prev, messages: [...prev.messages, ...res], loadMore: true }));
      }
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const checkDiffTime = (index: number) => {
    if (index === 0) return true;

    return (
      dayjs(state.messages[index - 1].date_created).format(CONTANTS.DATE) !==
      dayjs(state.messages[index].date_created).format(CONTANTS.DATE)
    );
  };

  const handleSend = async () => {
    if (!state.input) return;
    try {
      const res = await svMessage.create({
        conversation_id: state.conversation?.id,
        content: state.input,
      });

      setState((prev) => ({ ...prev, input: undefined, messages: [...prev.messages, res] }));
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    if (state.loadingUpload) return;
    setState((prev) => ({ ...prev, loadingUpload: true }));
    try {
      const res = await Promise.all(
        files.map(async (i) => {
          const file = await svFile.upload(i, {});

          return svMessage.create({
            file: file.id,
            conversation_id: state.conversation?.id,
          });
        }),
      );

      setState((prev) => ({ ...prev, loadingUpload: false, messages: [...prev.messages, ...res] }));
    } catch (error) {
      console.log(error);
    }
  };

  const info = useMemo<any>(() => {
    if (state.conversation) {
      if (state.conversation.users.length > 2) {
        return {
          avatar: state.conversation.avatar,
          title: state.conversation.name,
        };
      }
      if (state.conversation.users.length === 2) {
        const userReceiver = state.conversation.users.find(
          (i) => i.directus_users_id.id !== user?.id,
        )?.directus_users_id;

        return {
          avatar: userReceiver?.avatar,
          title: FUNCS.getFullName(userReceiver),
        };
      }
    }
  }, [state.conversation]);

  if (!state.open) return null;

  return (
    <div className="chat-window">
      <div className="chat-header">
        <div style={{ color: 'white', fontWeight: 'bold' }}>{info?.title}</div>
        <div>
          <CloseOutlined
            style={{ fontSize: 16, color: 'white' }}
            onClick={() => setState((prev) => ({ ...prev, open: false, conversation: undefined }))}
          />
        </div>
      </div>

      <div
        id="message"
        style={{
          height: '400px',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <BaseInfinityScroll
          dataLength={state.messages.length}
          customStyle={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          hasMore={state.loadMore}
          inverse
          scrollableTarget="message"
          fetchMoreData={() => {
            fetchMessage(state.messages.length);
          }}
        >
          <div className="chat-body">
            {state.messages
              .sort((a, b) => dayjs(a.date_created).diff(b.date_created))
              .map((msg: IMessages, index: number) => {
                const isUserSend = msg?.user_created === user?.id;
                const isDiffDate = checkDiffTime(index);
                const isLastMessage = index === state.messages.length - 1;

                return (
                  <div key={msg.id}>
                    {isDiffDate && <div className="diffDate">{dayjs(msg.date_created).format(CONTANTS.DATE)}</div>}

                    <div className={`message-content ${isUserSend ? 'sent' : 'received'}`}>
                      {msg.file ? (
                        <Image
                          width={140}
                          src={FUNCS.getFullMedialUrl(msg.file)}
                          style={{ objectFit: 'cover' }}
                          wrapperStyle={{ borderRadius: '6px', overflow: 'hidden' }}
                        />
                      ) : (
                        <div className="message">{msg.content}</div>
                      )}

                      {isLastMessage && (
                        <span className="status">
                          {isUserSend && lang.t('layout.sent')}
                          <p style={{ marginLeft: '3px' }}>{dayjs(msg.date_created).format('HH:mm')}</p>
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </BaseInfinityScroll>
      </div>

      <Row align="middle" style={{ padding: '12px 8px', borderTop: '1px solid #ccc' }}>
        <BaseUploadHidden onUpload={onUpload} accept="image/*">
          <img
            src="image/svg/image.svg"
            style={{ width: '28px', height: '28px', cursor: 'pointer' }}
            alt="avatar"
            title={lang.t('default.upload')}
          />
        </BaseUploadHidden>
        <div style={{ flex: 1, margin: '0 8px' }}>
          <BaseInput
            placeholder={lang.t('layout.enter_message')}
            autoFocus
            noMessage
            span={24}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSend();
            }}
            onChange={(e) => setState((prev) => ({ ...prev, input: e.target.value }))}
            value={state.input}
          />
        </div>

        <img
          onClick={handleSend}
          src="image/svg/send.svg"
          style={{ width: '28px', height: '28px', cursor: 'pointer' }}
          alt="avatar"
          title="Gửi"
        />
      </Row>
    </div>
  );
}

export default ChatWindow;
