import { Tabs } from 'antd';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import { memo, useEffect, useMemo, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Container, IconFont } from 'src/libraries';
import { ECollection, ItemService } from 'src/services';
import { IDepartment, ISetting } from 'src/types';
import { FUNCS } from 'src/utils';
import EditDepartmentScreen from './edit';

interface IProps {
  info: ISetting;
}

interface IState {
  detail?: IDepartment;
  loading: boolean;
  loadingSubmit: boolean;
}

const sv = new ItemService<IDepartment>(ECollection.department);

const initState: IState = {
  loading: false,
  loadingSubmit: false,
};

interface ITabActive {
  service?: string;
  action?: string;
}

function DetailDepartmentScreen(props: IProps) {
  const [state, setState] = useState<IState>(initState);
  const { id } = useParams();
  const { innerHeight: height } = window;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    id && fetchDetail(id);
  }, [id]);

  const fetchDetail = async (id: string) => {
    if (state.loading) return;
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const res = await sv.detail(id, {
        fields: [
          'id',
          'name',
          'code',
          'name',
          'parents.id',
          'parents.parent_id',
          'children.id',
          'children.child_id',
          'properties.id',
          'properties.property_id.id',
          'properties.property_id.label',
          'properties.property_id.value',
          'properties.property_id.image',
          'properties.property_id.icon',
          'properties.property_id.property_category_id.id',
        ],
      });
      setState((prev) => ({ ...prev, detail: res }));
    } catch (error) {
      console.log(error);
    }
    setState((prev) => ({ ...prev, loading: false }));
  };

  const tabActive = useMemo<ITabActive>(() => {
    const tab: ITabActive = FUNCS.getSearchParams(searchParams);
    return !tab.service ? { service: '0' } : tab;
  }, [searchParams]);

  const breadcrumbs = useMemo<Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[]>(() => {
    const result: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] = [
      {
        title: (
          <Link className="ant-breadcrumb-link" to={`/${FUNCS.renderPath(props.info.label)}`}>
            {props.info.label}
          </Link>
        ),
      },
    ];

    if (tabActive.service === '0') {
      result.push({ title: state.detail?.name });
    } else {
      const service = props.info.services.find((i) => i.id.toString() === tabActive.service);
      if (service) {
        result.push({
          title: (
            <Link className="ant-breadcrumb-link" to={`/${FUNCS.renderPath(props.info.label)}/${state.detail?.id}`}>
              {state.detail?.name}
            </Link>
          ),
        });
        result.push({
          title: service.label,
        });
      } else {
        result.push({ title: state.detail?.name });
      }
    }

    return result;
  }, [state.detail?.name, props.info, tabActive]);

  // useEffect(() => {
  //   const tabActiveNodes = document.querySelectorAll('.ant-tabs-tab');
  //   tabActiveNodes.forEach((i) => {
  //     if (i.classList.contains('ant-tabs-tab-active')) {
  //       // @ts-ignore
  //       i.style.backgroundColor = colorLight;
  //     } else {
  //       // @ts-ignore
  //       i.style.backgroundColor = 'transparent';
  //     }
  //   });
  // }, [tabActive]);

  return (
    <Container
      // title={`Thêm mới ${props.info.label}`}
      breadcrumbs={breadcrumbs}
      // renderRightBreadcrumb={
      //   <Button type="primary" loading={state.loading} onClick={() => form.submit()}>
      //     Thêm mới
      //   </Button>
      // }
    >
      <div style={{ borderTop: '1px solid rgba(5, 5, 5, 0.06)', margin: '0 -16px -20px' }}>
        {/* <Row>
          <div style={{ padding: '6px 8px' }}>
            <div className="tab">Chung</div>
            <div className="tab">Chung</div>
            <div className="tab active">Thêm mới</div>
            <div className="tab">Chung Chung</div>
            <div className="tab">Chung Chung Chung</div>
            <div className="tab">Chung</div>
          </div>
          <div style={{ flex: 1 }}>content</div>
        </Row> */}
        <Tabs
          activeKey={tabActive.service}
          tabPosition="left"
          // style={{ height: height - 115 }}
          tabBarStyle={{ height: height - 115 }}
          items={[
            {
              label: 'Chung',
              key: '0',
              children: <EditDepartmentScreen info={props.info} detail={state.detail} />,
            },
            ...props.info.services.map((i) => {
              return {
                label: i.label,
                key: i.id.toString(),
                children: `Content of tab ${i.label}`,
                icon: i.icon ? <IconFont type={`icon-${i.icon}`} /> : undefined,
              };
            }),
          ]}
          onChange={(key: string) => {
            setSearchParams({ service: key, action: 'list' });
          }}
        />
      </div>
    </Container>
  );
}

export default memo(DetailDepartmentScreen);
